import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Redirect } from 'react-router-dom'

import User from '../../models/User'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(' + User.baseURL + '/img/featured.png)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        // backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const countries = [
    { code: 'BR', label: 'Brazil' },
    { code: 'CA', label: 'Canada' },
    { code: 'CN', label: 'China' },
    { code: 'FR', label: 'France' },
    { code: 'DE', label: 'Germany' },
    { code: 'IE', label: 'Ireland' },
    { code: 'IT', label: 'Italy' },
    { code: 'JP', label: 'Japan' },
    { code: 'RU', label: 'Russia' },
    { code: 'ES', label: 'Spain' },
    { code: 'TR', label: 'Turkey' },
    { code: 'GB', label: 'United Kingdom' },
    { code: 'US', label: 'United States of America' },
]

export default function SignUp({ token, setToken }) {

    const [email, setEmail] = useState();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errors, setErrors] = useState([]);
    const [country, setCountry] = useState();
    const [sponsorCode, setSponsorCode] = useState(window.location.hash && window.location.hash.replace('#', '') ? window.location.hash.replace('#', '') : '');
    const [signUp, { data }] = useMutation(User.SIGNUP);
    const classes = useStyles();

    if (token) {
        return (<Redirect to="/" />);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        if (!email) setErrors(['email']);
        else if (!username) setErrors(['username']);
        else if (!password) setErrors(['password']);
        else if (!country) setErrors(['country']);
        else {

            try {
                // console.log("Email:", email, ", username: ", username, ", password:", password, "country:", country);
                const token = await signUp({ variables: { email, username, password, country, sponsorCode } });
                console.log("Set token : ", token);
                setToken(token.data.signUp);
    
            } catch (err) {
    
                // console.log("Err:", err);
                // console.log("Message :", err.message);
                // console.log("Has error email ?", err.message.indexOf('email'));
                let arr = [];
                if (err.message.indexOf('email') > -1) arr.push('email');
                // console.log("Has error username ?", err.message.indexOf('username'));
                if (err.message.indexOf('username') > -1) arr.push('username');
                // console.log("Has error password ?", err.message.indexOf('password'));
                if (err.message.indexOf('password') > -1) arr.push('password');
                // console.log("Has error country ?", err.message.indexOf('country'));
                if (err.message.indexOf('country') > -1) arr.push('country');
    
                setErrors(arr)
            }
        }
        
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

                <div className={classes.paper}>
                    <Avatar src="/img/profile-avatar.png" className={classes.avatar} />
                    <Typography component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    autoComplete="username"
                                    helperText={(errors.indexOf('username') > -1) ? 'Already exists' : null}
                                    error={(errors.indexOf('username') > -1) ? true : false}
                                    onChange={e => setUserName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    helperText={(errors.indexOf('email') > -1) ? 'Already exists' : null}
                                    error={(errors.indexOf('email') > -1) ? true : false}
                                    autoComplete="email"
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    helperText={(errors.indexOf('password') > -1) ? 'Must be > 8 letters, number, special chars.' : null}
                                    error={(errors.indexOf('password') > -1) ? true : false}
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    name="sponso"
                                    label="Sponsored ?"
                                    type="text"
                                    value={sponsorCode ? "You are sponsored !" : "You are not sponsored"}
                                    id="sponsor"
                                    autoComplete="current-sponsor"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                <Select
                                    helperText={(errors.indexOf('country') > -1) ? 'Required' : null}
                                    error={(errors.indexOf('country') > -1) ? true : false}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={e => setCountry(e.target.value)}
                                >
                                    {countries.map(c => (<MenuItem value={c.code}>{c.label}</MenuItem>))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign Up
                    </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="/signin" variant="body2">
                                    Already have an account? Sign in
                            </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={5}>
                    {/* <Copyright /> */}
                </Box>
            </Grid >
        </Grid >
    )
}

SignUp.propTypes = {
    setToken: PropTypes.func.isRequired
}
