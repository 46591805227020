import React, { useState } from 'react';

// import { apolloClient } from '@apollo/client';

import { Plugins } from '@capacitor/core';

import { fade } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';

import Alert from '@material-ui/lab/Alert';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import { Grid, BottomNavigation, BottomNavigationAction, Chip } from '@material-ui/core';


import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PublicIcon from '@material-ui/icons/Public';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FlagIcon from '@material-ui/icons/Flag';
import AssignmentIcon from '@material-ui/icons/Assignment';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import Actions from '../Actions/Actions';
import SmallProfile from '../Profile/SmallProfile';
import ProfilePage from '../Profile/ProfilePage';
import PartyPage from '../Party/PartyPage';
import CountryPage from '../Country/CountryPage';
import World from '../World/World';

import { withRouter, Redirect, BrowserRouter, Link, Route, Switch } from 'react-router-dom';

import User from '../../models/User'
import numeral from 'numeral'

const { GameServices } = Plugins;
// import { GooglePlayGamesServices } from '@ionic-native/google-play-games-services';



const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    iconBar: {
        display: 'flex'
    },
    iconAppBar: {
        fontSize: 16,
        margin: 4,
        marginRight: 10,
    },
    grow: {
        flexGrow: 1,
    },
    centerBottom: {
        // alignContent: 'center',
        // width: '100%',
        // display: 'block',
        // position: 'relative',
    },
    stickToBottom: {
        width: '100%',
        display: 'flex',
        // justifyContent: 'center',
        // boxSizing: 'border-box',
        // flexShrink: 0,
        // position: 'static',
        // alignContent: 'center',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // marginLeft: -210,
        // flexDirection: 'column',
        // align: 'center',
        position: 'fixed',
        bottom: 0,
    },
    title: {
        display: 'none',
        // [theme.breakpoints.up('sm')]: {
        //     display: 'block',
        // },
    },
    search: {
        position: 'relative',
        // borderRadius: theme.shape.borderRadius,
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            // backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        // marginRight: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: theme.spacing(3),
        //     width: 'auto',
        // },
    },
    searchIcon: {
        // padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        // padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        // transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('md')]: {
        //     width: '20ch',
        // },
    },
    sectionDesktop: {
        display: 'flex',
        // [theme.breakpoints.up('md')]: {
        //     display: 'flex',
        // },
    },
    sectionMobile: {
        // display: 'flex',
        // [theme.breakpoints.up('md')]: {
        //     display: 'none',
        // },
    },
    userLevel: {
        width: 35,
        height: 35,
        fontSize: 16,
        margin: 5,
        padding: 3
    }
});

class Home extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            currentMenu: 'actions',
            currentUser: null
        }

        this.onUpdateUser = this.onUpdateUser.bind(this);

        // const [currentMenu, setCurrentMenu] = useState(0);
        // const { currentUser, setCurrentUser } = useCurrentUser();
        // const apolloClient = useApolloClient();

    }

    async componentDidMount() {

        await this.onUpdateUser();

        try {
            if (GameServices) {
                const data = await GameServices.signIn();
                console.log("Data", data);
            }
        } catch (err) {
            console.log("Error gameservices :", err)
        }

    }

    async onUpdateUser() {
        if (this.props.token) {
            const { data, loading } = await this.props.apolloClient.query({ query: User.ME, fetchPolicy: "no-cache" });
            let obj = JSON.parse(JSON.stringify(data.me));
            this.setState({ currentUser: obj });
        }
    }

    render() {



        // console.log("Props home :", this.props);
        console.log("Token home : ", this.props.token);
        console.log("> Render Home");

        if (this.props.token === false) {
            // Got to signin:
            return (<Redirect to="/signin" />);
        }


        if (this.props.token && !this.state.currentUser) {

            this.onUpdateUser();
        }

        if (!this.state.currentUser) return (<Typography>Loading ...</Typography>);

        const { location, classes } = this.props;

        // const classes = {};

        // const user = getCurrentUser();
        // if (!user) {
        //     const { data, loading } = await apolloClient.query({ query: User.ME });
        //     user = data.me;
        // }

        // console.log("Token : ", token, ", current menu :", currentMenu);


        console.log("Pathname : ", window.location.pathname);
        console.log("Location object :", location);

        let todoList = {
            actions: [],
            profile: [],
            party: [],
            country: [],
            world: []
        }


        if (!this.state.currentUser.values || this.state.currentUser.values.length < 3) {
            todoList.profile.push('You should choose 3 to 5 more values you believe in. This is politics ...');
        }

        if (!this.state.currentUser.options || (this.state.currentUser.options.length < 1 && this.state.currentUser.resources.money > 20000)) {
            todoList.profile.push('You can purchase skills and traits in order to improve your character, increase success and rewards...');
        }

        if (!this.state.currentUser.party) {
            todoList.party.push('You should select a party to be a member of. You will never succeed alone.')
        }

        if (this.state.currentUser.resources.points < 4) {
            todoList.actions.push('Do actions to earn money and points. You will be liked and disliked in politics. That\'s life.');
        }

        // if (this.state.currentUser.resources.psx < 1) {
        //     todoList.country.push('You should invite friends to Politics Simulator. You will earn 10 PSx for each new player with your partner code.');
        // }

        const currentTodoList = todoList[window.location.pathname.replace('/home/', '')]

        console.log("Home props :", this.props);

        return (
                <div className={classes.root}>
                    <BrowserRouter>
                        <CssBaseline />
                        <AppBar position="fixed">
                            <Toolbar>
                                {/* <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                        >
                            <MenuIcon />
                        </IconButton> */}
                                <Avatar className={classes.userLevel}>{(this.state.currentUser) ? this.state.currentUser.level : ''}</Avatar>
                                <h2>{(this.state.currentUser) ? this.state.currentUser.username : ''}</h2>
                                <div className={classes.grow} />
                                <div className={classes.iconBar}>
                                    {(this.state.currentUser) ? numeral(this.state.currentUser.resources.money).format('0.[0]a') : ''}
                                    <AttachMoneyIcon className={classes.iconAppBar} />

                                    {(this.state.currentUser) ? numeral(this.state.currentUser.resources.like).format('0.[0]a') : ''}
                                    <FavoriteIcon className={classes.iconAppBar} />

                                    {(this.state.currentUser) ? numeral(this.state.currentUser.resources.dislike).format('0.[0]a') : ''}
                                    <ThumbDownIcon className={classes.iconAppBar} />

                                    {(this.state.currentUser) ? numeral(this.state.currentUser.resources.points).format('0.[0]a') : ''}
                                    <OfflineBoltIcon className={classes.iconAppBar} />

                                    {(this.state.currentUser) ? numeral(this.state.currentUser.resources.psx).format('0.[0]a') : ''}
                                    <LocalAtmIcon className={classes.iconAppBar} />

                                </div>
                            </Toolbar>
                        </AppBar>
                        <Toolbar />

                        {currentTodoList.map(t => (<Alert severity="info">{t}</Alert>))}

                        <Grid container spacing={2} style={{ marginBottom: '75px' }}>
                            <Grid item xs={12} md={12}>

                                <Route path="/home/actions">
                                    <Actions memory={this.props.memory} apolloClient={this.props.apolloClient} currentUser={this.state.currentUser} onUpdateUser={this.onUpdateUser} />
                                </Route>
                                <Route path="/home/profile">
                                    <ProfilePage memory={this.props.memory} apolloClient={this.props.apolloClient} currentUser={this.state.currentUser} onUpdateUser={this.onUpdateUser} />
                                </Route>
                                <Route path="/home/party">
                                    <PartyPage memory={this.props.memory} apolloClient={this.props.apolloClient} currentUser={this.state.currentUser} onUpdateUser={this.onUpdateUser} />
                                </Route>
                                <Route path="/home/country">
                                    <CountryPage memory={this.props.memory} apolloClient={this.props.apolloClient} currentUser={this.state.currentUser} onUpdateUser={this.onUpdateUser} />
                                </Route>
                                <Route path="/home/world">
                                    <World memory={this.props.memory} apolloClient={this.props.apolloClient} currentUser={this.state.currentUser} onUpdateUser={this.onUpdateUser} />
                                </Route>
                                {/* <Route exact path="/home" render={() => (<Redirect to="/home/actions" />)} /> */}

                            </Grid>
                        </Grid>

                        {/* <div className={classes.centerBottom}> */}

                        <BottomNavigation
                            position="fixed"
                            className={classes.stickToBottom}
                            onChange={(event, newValue) => {
                                this.setState({ currentPath: newValue })
                            }}
                            showLabels
                        >
                            <BottomNavigationAction component={Link} to="/home/actions" label="Actions" value="actions" icon={<Badge badgeContent={todoList.actions.length} color="error"><SettingsIcon color={window.location.pathname.indexOf('home/actions') > -1 ? 'primary' : ''} /></Badge>} />
                            <BottomNavigationAction component={Link} to="/home/profile" label="Profile" value="profile" icon={<Badge badgeContent={todoList.profile.length} color="error"><AccountBoxIcon color={window.location.pathname.indexOf('home/profile') > -1 ? 'primary' : ''} /></Badge>} />
                            <BottomNavigationAction component={Link} to="/home/party" label="Party" value="party" icon={<Badge badgeContent={todoList.party.length} color="error"><AssignmentIcon color={window.location.pathname.indexOf('home/party') > -1 ? 'primary' : ''} /></Badge>} />
                            <BottomNavigationAction component={Link} to="/home/country" label="Country" value="country" icon={<Badge badgeContent={todoList.country.length} color="error"><FlagIcon color={window.location.pathname.indexOf('home/country') > -1 ? 'primary' : ''} /></Badge>} />
                            <BottomNavigationAction component={Link} to="/home/world" label="World" value="world" icon={<Badge badgeContent={todoList.world.length} color="error"><PublicIcon color={window.location.pathname.indexOf('home/world') > -1 ? 'primary' : ''} /></Badge>} />
                        </BottomNavigation>
                    </BrowserRouter>
                </div>
        );
    }

}

export default withRouter(withStyles(styles)(Home));