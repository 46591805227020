import React, { useState } from 'react';
import { gql } from '@apollo/client';
// import API from './api.js'

import { getCurrentUser } from '../hooks/memory';

const User = {

    NB_NOTIONS: 29,

    baseURL: (window.location.href.indexOf('//localhost:') > -1) ? 'http://localhost:8100' : 'https://www.politics-simulator.com',
    baseApiURL: (window.location.href.indexOf('//localhost:') > -1) ? 'http://localhost:5000' : 'https://api.politics-simulator.com',
    // baseURL: 'https://www.politics-simulator.com',
    // baseApiURL: 'https://api.politics-simulator.com',

    SIGNUP: gql`mutation signUp( $username: String!, $email: String!, $password: String!, $country: String!, $sponsorCode: String ) { signUp(username:$username, email:$email, password:$password, country:$country, sponsorCode:$sponsorCode) { token } }`,
    SIGNIN: gql`mutation signIn($login: String!, $password: String!) { signIn(login: $login, password: $password) { token } }`,

    PASSWORDFORGOTTEN: gql`mutation passwordForgotten($login: String!) { passwordForgotten(login: $login) }`,
    UPDATEPASSWORD: gql`mutation updatePassword($authToken: String!, $newPassword: String!) { updatePassword(authToken: $authToken, newPassword: $newPassword) }`,


    ME: gql`{me{id,username,taxRate,messagingToken,values,party{id,name,values,totalResources{like,dislike,points,nbMembers},country},country,level,email,isCandidate,isPresident,resources{money,like,dislike,points,psx},currentActions{code,resources{money,like,psx},startDate,endDate},lastActions{code,resources{money,like,psx},earnings{money,like,dislike,psx,points}},invests{code,money,country},currentAttacks{code,attacker,resources{money,like,psx,points},startDate,endDate},lastAttacks{code,attacker,succeed,loss{money,like}},options,news{newsType,code,data}}}`,
    DOACTIONS: gql`mutation doActions($actions: [ActionConfiguration]!){doActions(actions:$actions)}`,
    
    USER: gql`query user($id: String!) {user(id: $id){id,username,values,level,party{id,name,values,totalResources{like,dislike,points,nbMembers},country},country,isCandidate,isPresident,resources{points,like,dislike}}}`,
    
    PARTYS: gql`query partys($country: String!, $nb: Int!, $values: [String]) {partys(country: $country, nb: $nb, values: $values){id,name,values,country,values,totalResources{like,dislike,points,nbMembers}}}`,
    UPDATEUSER: gql`mutation updateUser( $party: ID, $email: String, $isCandidate: Boolean, $messagingToken: String, $values: [String], $taxRate: Float ) { updateUser(party:$party,email:$email,isCandidate:$isCandidate,messagingToken:$messagingToken, values:$values, taxRate:$taxRate) { id,username,messagingToken,taxRate,values,party{name,values,totalResources{like,dislike,points,nbMembers}},country,email,isCandidate,isPresident,resources{money,like,dislike},currentActions{code,startDate,endDate},invests{code,country,money} } }`,
    CREATEPARTY: gql`mutation createParty($name: String!,$country: String!,$values: [String!]) { createParty(name:$name, country:$country, values:$values) {id,name,values,country,values} }`,
    
    OPTIONS: gql`query options {options{id,level,type,category,cost{money,like,psx},icon,needs}}`,
    BUYOPTION: gql`mutation buyOption($code: String!){buyOption(code:$code)}`,
    ATTACKUSER: gql`mutation attackUser($attackedId: String!, $attackCode: String!, $resources: ResourcesInput!){ attackUser(attackedId:$attackedId, attackCode:$attackCode, resources:$resources)}`,

    COUNTRIES: gql`{countries{name,code,metrics{date,gdp,generalTaxRate,president{id,username,party{id,name,values}}},stats{nbPlayers,points,values{key,nb}},budget{HEALTH,DEFENCE,SECURITY,EDUCATION,SOCIAL,INDUSTRY,SERVICES,SPORT,CULTURE,TOURISM,FINANCE},currentEvents{startDate,gravityIndex,code,domain,moneyLevel,moneyInvestedPro,moneyInvestedCon}}}`,
    COUNTRY: gql`query country($code: String!) {country(code: $code) {name,code,stats{nbPlayers,points,values{key,nb}},metrics{date,gdp,generalTaxRate,president{id,username,party{id,name,values}}},budget{HEALTH,DEFENCE,SECURITY,EDUCATION,SOCIAL,INDUSTRY,SERVICES,SPORT,CULTURE,TOURISM,FINANCE},currentEvents{startDate,gravityIndex,code,domain,moneyLevel,moneyInvestedPro,moneyInvestedCon}}}`,
    
    BUYINVEST: gql`mutation buyInvest( $code: String!, $money: Int!, $country: String!) { buyInvest(code:$code, money:$money, country:$country)  }`,
    SELLINVEST: gql`mutation sellInvest( $code: String!, $money: Int!, $country: String!) { sellInvest(code:$code, money:$money, country:$country)  }`,
    INVESTPROEVENT: gql`mutation investProEvent( $countryCode: String!, $eventCode: String!, $date: Date!, $money: Int!) { investProEvent(countryCode:$countryCode, eventCode:$eventCode, date:$date, money:$money)  }`,
    INVESTCONEVENT: gql`mutation investConEvent( $countryCode: String!, $eventCode: String!, $date: Date!, $money: Int!) { investConEvent(countryCode:$countryCode, eventCode:$eventCode, date:$date, money:$money)  }`,
    SETBUDGET: gql`mutation setBudget( $budget: BudgetInput! ) { setBudget(budget:$budget) }`,

    GLOBALSTATS: gql`{statsGlobal{users{id,username,isPresident,isCandidate,values,party{id,name,values,totalResources{like,dislike,points,nbMembers},country},level,country,resources{points,like,dislike}},parties{id,name,values,country,values,totalResources{like,dislike,points,nbMembers}}}}`,
    COUNTRYSTATS: gql`query statsCountry($countryCode: String!){statsCountry(countryCode: $countryCode){users{id,username,resources{points},level,isPresident,isCandidate,party{id,name,values,totalResources{like,dislike,points,nbMembers},country},country,resources{like,dislike}}}}`,
    PARTYSTATS: gql`query statsParty($party: String!) {statsParty(party: $party){users{id,username,isPresident,isCandidate,level,country,party{id,name,values,totalResources{like,dislike,points,nbMembers},country},resources{like,dislike,points}}}}`,
}


User.getUser = function() {
    if (!this._user) this.update();
    return this._user;
}

User.getResources = function () {
    if (!this._user) this.update();
    return this._user?.resources;
}

User.hasEnoughResources = function(user, resources) {
    for (let k in resources) {
        // console.log("Compare :", k, user.resources[k], resources[k]);
        if (!user.resources.hasOwnProperty(k) || (user.resources[k] < resources[k])) {
            return false;
        }
    }
    return true;
}

export default User;