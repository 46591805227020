import React, { Suspense } from 'react';
import { withStyles } from '@material-ui/styles';

import { apolloClient } from '@apollo/client';

import { Grid, Chip, Badge, Avatar, Typography, Divider, List, ListItem, Slider, CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Slide from '@material-ui/core/Slide';

// import Tree from 'react-tree-graph';
// import 'react-tree-graph/dist/style.css';
// import './tree.css';

import Button from '@material-ui/core/Button';

import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';


import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import User from '../../models/User'

import { withTranslation } from 'react-i18next'

import SmallProfile from '../Profile/SmallProfile'
import SmallParty from '../Party/SmallParty'

import Memory from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'
import { DateTime, Duration, Interval } from 'luxon';
import numeral from "numeral"

import Flag from 'react-flagkit'

// const [doActions, { data }] = useMutation(User.DOACTIONS);


let classes = {
    margin: {
        height: 60,
    },
};

class PSOption extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        if (!this.props.userOptions) return null;
        if (!this.props.option) return null;

        const Icon = React.lazy(() => import(/* webpackMode: "eager" */`@material-ui/icons/${this.props.option.icon}`));

        const { onHandleAction } = this.props;

        let alreadyHas = false;
        let canBuy = false;
        let showDepends = false;

        if (this.props.userOptions.indexOf(this.props.option.id) > -1) {
            /* J'ai déjà : */
            // Afficher le chip avec l'avatar, le nom, mais pas le coût
            alreadyHas = true;
        }

        // Check has all dependencies :
        if (this.props.userOptions.filter(uo => this.props.option.needs.indexOf(uo) > -1).length === this.props.option.needs.length) {
            /* Je peux acheter : */
            // Can buy by clicking on it : print dialog to buy it
            // TODO : Check if has resources :
            canBuy = true;

        } else {
            // Else : Cannot buy it
            /* Impossible d'acheter  car il faut tel achat précédent : */
            // Show what it depends on
            showDepends = true;
        }

        if (!canBuy) return null;

        return (
            <Suspense fallback="loading...">
                <Chip
                    icon={<Icon />}
                    label={this.props.t(this.props.option.id)}
                    clickable
                    onClick={() => { onHandleAction(); }}
                    color={(alreadyHas) ? "primary" : "secondary"}
                    // onDelete={onHandleAction}
                    deleteIcon={(!alreadyHas && canBuy) ? (<ShoppingCartIcon />) : (<CloseIcon />)}
                    style={{ margin: 5 }}
                />
            </Suspense>

        );

    }
}



class IncomingAttack extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { attack, t } = this.props;

        return (<Card>
            <CardHeader title={t('UI_' + attack.code + '_TITLE')} subheader="" />
            <CardContent>
                Attacker : <SmallProfile apolloClient={this.props.apolloClient} id={attack.attacker} memory={this.props.memory} currentUser={this.props.currentUser} onUpdateUser={this.props.onUpdateUser} />
                <Typography>Resources spent :
                    {attack.resources['money']} money,
                    {attack.resources['like']} likes,
                    {attack.resources['points']} points,
                    {attack.resources['psx']} psx </Typography>
                <Typography>End at: {attack.endDate}</Typography>
            </CardContent>
        </Card>)
    }
}





class Profile extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            country: { metric: [{ generalTaxRate: 0.07 }] },
            user: (this.props.currentUser) ? this.props.currentUser : this.props.user,
            isCurrent: (this.props.currentUser) ? true : false,
            arrOptions: []
        }

        this.taxChangeTimeout = false
    }

    async componentDidMount() {

        let { data, loading } = await this.props.apolloClient.query({ query: User.COUNTRY, variables: { code: this.state.user.country } });
        let country = JSON.parse(JSON.stringify(data.country));

        let arrOptions = await this.props.memory.getOptions();
        this.setState({
            arrOptions,
            country
        });
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {

        if (!this.state.user) return null

        const { t, onUpdateUser, apolloClient } = this.props

        const user = this.props.currentUser
        if (!user.values) user.values = []

        if (!this.state.country.metric || this.state.country.metric.length === 0) this.state.country = { metric: [{ generalTaxRate: 0.07 }] }

        const previousLevel = user.level - 1
        const currentLevel = user.level
        const nextLevel = user.level + 1

        const previousLevelValue = Math.pow(2, previousLevel - 1)
        const currentLevelValue = Math.pow(2, currentLevel - 1)
        const nextLevelValue = Math.pow(2, nextLevel - 1)

        let arrValues = [...Array(User.NB_NOTIONS).keys()].map(n => ({ key: 'NOTION' + n, label: t('NOTION' + n) }))
        arrValues.sort((n1, n2) => (n1.label < n2.label) ? -1 : 1)

        const marks = [
            {
                value: previousLevelValue,
                label: previousLevel,
            },
            {
                value: currentLevelValue,
                label: currentLevel,
            },
            {
                value: nextLevelValue,
                label: nextLevel,
            }
        ]

        // console.log("Marks :", marks)

        let dataSkills = {}
        let dataTraits = {}
        const arrOptions = this.state.arrOptions

        const handleBuyOption = async () => {

            const ret = await this.props.apolloClient.mutate({ mutation: User.BUYOPTION, variables: { code: this.state.openBuyDialog.id } })
            await this.props.onUpdateUser()
            this.setState({ openBuyDialog: null })
        }
        const handleCloseBuyOption = () => {
            this.setState({ openBuyDialog: null })
        }
        const onHandleOpenBuyOption = (option) => {
            this.setState({ openBuyDialog: option })
        }

        const canBuy = (option) => {

            // User has resources :
            const hasResources = User.hasEnoughResources(user, option.cost)

            // AND has all needed options :
            const hasNeeded = user.options.filter(o => option.needs.indexOf(o) > -1).length === option.needs.length

            return { hasResources, hasNeeded }
        }


        let canBuyOption = false
        let alreadyHaveOption = false
        if (this.state.openBuyDialog && user) {
            const { hasResources, hasNeeded } = canBuy(this.state.openBuyDialog)
            canBuyOption = hasResources && hasNeeded

            if (user.options.find(o => o === this.state.openBuyDialog.id)) {
                alreadyHaveOption = true
            }
        }

        console.log("Option for dialog :", this.state.openBuyDialog)

        const updateUserValues = async (value) => {

            // Toggle this value in currentUser :
            if (user.values.find(o => o === value)) {
                // Remove it :
                user.values = user.values.filter(o => o !== value)

            } else {
                // Add it :
                if (user.values.length >= 5) {
                    alert('You cannot believe in everything. Choose max 5 values.')
                    return
                }
                user.values.push(value)
            }

            // After toggling, updateUser :
            await this.props.apolloClient.mutate({ mutation: User.UPDATEUSER, variables: { values: user.values } })
            await this.props.onUpdateUser()
        }

        const updateUserTaxRate = async (newTaxRate) => {

            // After toggling, updateUser :
            await this.props.apolloClient.mutate({ mutation: User.UPDATEUSER, variables: { taxRate: newTaxRate } })
            await this.props.onUpdateUser()
        }

        let protectedMoney = 0
        if (user.options.find(o => o === 'VAULT_1')) protectedMoney += 500000
        if (user.options.find(o => o === 'VAULT_2')) protectedMoney += 5000000
        if (user.options.find(o => o === 'VAULT_3')) protectedMoney += 100000000
        if (user.options.find(o => o === 'VAULT_4')) protectedMoney += 1000000000

        return (
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={12} md={6}>
                    <Paper style={{ margin: '10px', padding: '15px' }}>
                        <h2>Identity</h2>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Country</TableCell>
                                        <TableCell align="right">
                                            <Flag country={user.country} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Points</TableCell>
                                        <TableCell align="right" style={{ padding: '20px' }}>
                                            <Slider
                                                disabled={true}
                                                min={previousLevelValue}
                                                max={nextLevelValue}
                                                defaultValue={user.resources.points}
                                                getAriaValueText={(v) => v}
                                                step={null}
                                                valueLabelDisplay="on"
                                                marks={marks}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">People opinion</TableCell>
                                        <TableCell align="right" style={{ padding: '20px' }}>
                                            <Slider
                                                disabled={true}
                                                min={0}
                                                max={100}
                                                defaultValue={parseInt(100 * user.resources.like / (user.resources.like + user.resources.dislike))}
                                                getAriaValueText={(v) => { return "." }}
                                                style={{
                                                    root: {
                                                        color: (user.resources.like > user.resources.dislike) ? '#52af77' : '#ff3333'
                                                    },
                                                    track: {
                                                        height: 8,
                                                        borderRadius: 4,
                                                    },
                                                    rail: {
                                                        height: 8,
                                                        borderRadius: 4,
                                                    }
                                                }}
                                                step={null}
                                                valueLabelDisplay="on"
                                                marks={[{
                                                    value: 0,
                                                    label: 'Hated'
                                                }, {
                                                    value: 25,
                                                    label: 'Disliked'
                                                }, {
                                                    value: 50,
                                                    label: 'Neutral'
                                                }, {
                                                    value: 75,
                                                    label: 'Liked'
                                                }, {
                                                    value: 100,
                                                    label: 'Loved'
                                                }]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Party</TableCell>
                                        <TableCell align="right">{(user.party) ? <SmallParty currentUser={user} apolloClient={apolloClient} onUpdateUser={onUpdateUser} party={user.party} /> : 'Indep'}</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Tax rate</TableCell>
                                        <TableCell align="right">
                                            <Slider
                                                defaultValue={user.taxRate}
                                                valueLabelFormat={v => `${parseInt(v * 100)}%`}
                                                onChange={(e, newTaxRate) => { clearTimeout(this.taxChangeTimeout); this.taxChangeTimeout = setTimeout(() => { updateUserTaxRate(newTaxRate) }, 200); }}
                                                marks={[{ value: this.state.country.metric[0].generalTaxRate, label: 'National tax rate: ' + parseInt(this.state.country.metric[0].generalTaxRate * 100) + '%' }]}
                                                min={0.02}
                                                max={0.75}
                                                step={0.01}
                                                track={false}
                                                valueLabelDisplay="on"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper style={{ margin: '10px', padding: '15px', maxHeight: '200px', overflowY: 'auto' }}>
                        <h2>News</h2>
                        {(user.news.length === 0) ? t('NO_NEWS') :
                            user.news.map(news => (
                                <Card>
                                    <CardContent>
                                        {t(news.code, news.data ? JSON.parse(news.data) : {})}
                                    </CardContent>
                                </Card>
                            ))
                        }
                    </Paper>

                    <Paper style={{ margin: '10px', padding: '15px' }}>
                        <h2>Incoming attacks</h2>
                        {(user.currentAttacks && user.currentAttacks.length > 0) ?
                            user.currentAttacks.map(a => (
                                <IncomingAttack apolloClient={this.props.apolloClient} t={this.props.t} attack={a} memory={this.props.memory} currentUser={this.props.currentUser} onUpdateUser={this.props.onUpdateUser} />
                            )) :
                            (<Typography>No attacks for now ... you are not that important ;)</Typography>)}
                    </Paper>


                    <Paper style={{ margin: '10px', padding: '15px' }}>
                        <h2>Last attacks</h2>
                        {(user.lastAttacks && user.lastAttacks.length > 0) ?
                            user.lastAttacks.map(a => (
                                <Card>
                                    <CardHeader avatar={(a.succeed) ? <CheckIcon /> : <NotInterestedIcon />} title={t('UI_' + a.code + '_TITLE')} />
                                    <CardContent>
                                        {(a.succeed) ? (<Typography>
                                            You lost :
                                            { (a.loss['money']) ? a.loss['money'] + ' $' : ''}
                                            { (a.loss['like']) ? a.loss['like'] + ' likes' : ''}
                                            { (a.loss['point']) ? a.loss['point'] + ' points' : ''}
                                            { (a.loss['psx']) ? a.loss['psx'] + ' psx' : ''}
                                        </Typography>) : (<Typography>You lost : Nothing</Typography>)}
                                        Attacker : <SmallProfile apolloClient={this.props.apolloClient} id={a.attacker} memory={this.props.memory} currentUser={this.props.currentUser} onUpdateUser={this.props.onUpdateUser} />
                                    </CardContent>
                                </Card>
                            )) :
                            (<Typography>Never been attacked ... yet ;)</Typography>)}
                    </Paper>


                </Grid>

                <Grid item xs={12} md={12}>
                    <h2>Your Values</h2>
                    <h6>What you believe in</h6>

                    <Grid container spacing={2}>
                        {arrValues.map(v => (
                            <Grid item xs={6} sm={4} md={3}>
                                <FormControlLabel
                                    control={<Switch size="small" checked={!!user.values.find(o => o == v.key)} onChange={() => { updateUserValues(v.key) }} />}
                                    label={v.label}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                    <h2>Skills, Equipment & Traits</h2>

                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <Card>
                                <CardHeader title={t('The bad skills')} subheader={t('More fun, more realistic, but should have some surprises')} />
                                <CardContent>
                                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        {arrOptions.filter(o => o.category === 'BAD').map(o => (<PSOption option={o} t={this.props.t} userOptions={user.options} onHandleAction={() => { onHandleOpenBuyOption(o) }} />))}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card >
                                <CardHeader title={t('The neutral skills')} subheader={t('General skills')} />
                                <CardContent>
                                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        {arrOptions.filter(o => o.category === 'NEUTRAL').map(o => (<PSOption option={o} t={this.props.t} userOptions={user.options} onHandleAction={() => { onHandleOpenBuyOption(o) }} />))}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card>
                                <CardHeader title={t('The good way')} subheader={t('Maybe a little naïve, don\'t you think ?')} />
                                <CardContent>
                                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        {arrOptions.filter(o => o.category === 'GOOD').map(o => (<PSOption option={o} t={this.props.t} userOptions={user.options} onHandleAction={() => { onHandleOpenBuyOption(o) }} />))}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                {(this.state.openBuyDialog) ? (
                    <Dialog
                        open={this.state.openBuyDialog}
                        TransitionComponent={Slide}
                        keepMounted
                        onClose={handleCloseBuyOption}
                        aria-labelledby="dialog-buy-option-title"
                        aria-describedby="dialog-buy-option-description"
                    >
                        <DialogTitle id="dialog-buy-option-title">{"What about this option ?"}</DialogTitle>
                        <DialogContent>
                            <Typography>{t([this.state.openBuyDialog.id + '_DESC', 'OPTION_DEFAULT_DESC'])}</Typography>
                            {(canBuyOption) ? (
                                <DialogContentText id="dialog-buy-option-description">
                                    This option costs :
                                    {this.state.openBuyDialog.cost.money} <AttachMoneyIcon />
                                    {this.state.openBuyDialog.cost.like} <FavoriteIcon />
                                    {this.state.openBuyDialog.cost.psx} <OfflineBoltIcon />
                                </DialogContentText>
                            ) : (this.state.openBuyDialog) ? (
                                <DialogContentText id="dialog-buy-option-description">
                                    You cannot buy this option :
                                    {this.state.openBuyDialog.cost.money} <AttachMoneyIcon />
                                    {this.state.openBuyDialog.cost.like} <FavoriteIcon />
                                    {this.state.openBuyDialog.cost.psx} <OfflineBoltIcon />
                                And needs :
                                    <ul>
                                        {(this.state.openBuyDialog.needs.length === 0) ? t('UI_NO_NEED') : this.state.openBuyDialog.needs.map(n => t(n))}
                                    </ul>
                                </DialogContentText>
                            ) : null}

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseBuyOption} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleBuyOption} disabled={!canBuyOption || alreadyHaveOption} color="primary">
                                Buy
                            </Button>
                        </DialogActions>
                    </Dialog>) : null}

            </Grid>
        );
    }

}

export default withStyles(classes)(withTranslation()(Profile));
