import React, { Suspense, useState, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';

import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { Chip, Grid, Avatar } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';

import User from '../../models/User'
import { saveCurrentUser, disconnect } from '../../hooks/memory'
import { shortNumber } from '../../hooks/numberUtils'
import Profile from './Profile'

import Flag from 'react-flagkit'


class SmallProfile extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            isDialogOpen: false
        }

        // const apolloClient = useApolloClient();

        
        this.handleDialogClose = this.handleDialogClose.bind(this);
        
        // console.log("SmallProfile : Current ?", current, ", id:", id);
        // console.log("Res : ", loading, error, data);
    }
    
    handleDialogClose() {
        this.props.history.goBack();
        
        this.setState({ isDialogOpen: false });
    }
    
    async componentDidMount() {
        
        if (!this.props.user && !this.state.user && this.props.id) {
    
            // const { loading, error, data } = useQuery(User.USER, { variables: { id } });
            const { data } = await this.props.apolloClient.query({ query: User.USER, variables: { id: this.props.id } });
            this.setState({ user: JSON.parse(JSON.stringify(data.user)) })
        }
    }
    
    render() {
        
        // console.log("Props :", window.location);
        const { currentUser, apolloClient, onUpdateUser, memory } = this.props;
        let user;
        if (this.props.user) {
            user = this.props.user
        } else if (!this.props.user && this.state.user) {
            user = this.state.user
        }

        const handleClick = () => {
            this.props.history.push('#user-dialog-'+user.id);
            this.setState({ isDialogOpen: true });
        };

        console.log("User :", user);

        if (!user) return null;

        const openDialog = window.location.href.indexOf('#user-dialog-'+user.id) > -1 

        return (
            <Suspense fallback="Loading ...">
                <Chip
                    color="primary"
                    clickable
                    size="large"
                    onClick={handleClick}
                    icon={<Flag country={user.country.toUpperCase()} style={{ fontSize: 17, marginLeft: 10 }} />}
                    label={user.username
                        + ' | '
                        + 'lvl. ' + user.level
                        + ' | '
                        + user.resources.like + '/' + user.resources.dislike
                        // + ' | '
                        // + ((user.party) ? user.party.name : 'Indep.')
                        + ' | ' + shortNumber(user.resources.points) + " pts"
                    }
                />
                { (user.isPresident) ? (<Chip color="secondary" label="president" />) : "" }
                { (user.isCandidate) ? (<Chip color="secondary" label="candidate" />) : "" }
                <Profile currentUser={currentUser} user={user} memory={memory} open={openDialog} onUpdateUser={onUpdateUser} apolloClient={apolloClient} handleClose={this.handleDialogClose} />
            </Suspense>
        );
    }

}

export default withRouter(withTranslation()(SmallProfile));
