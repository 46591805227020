import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Redirect, useParams, useHistory } from 'react-router-dom'

import User from '../../models/User'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(' + User.baseURL + '/img/featured.png)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        // backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function UpdatePassword({ memory, token, setToken }) {

    const [password, setPassword] = useState();
    const [passwordError, setPasswordError] = useState('');
    const [updatePassword, { data }] = useMutation(User.UPDATEPASSWORD);
    const classes = useStyles();
    const { authToken } = useParams();
    const history = useHistory();

    if (token) {
        return (<Redirect to="/" />);
    }

console.log("AuthToken :", authToken);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const result = await updatePassword({ variables: { authToken, newPassword: password } });
            console.log("[updatePassword] Result:", result);
            if (result.data.updatePassword) {

                history.push('/signin');

            } else {
                // Show errors :
                setPasswordError('An error occured');
            }
        } catch (err) {

            console.log("Err:", err);
            setPasswordError('An error occured');
        }
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

                <div className={classes.paper}>
                    <Avatar src="/img/profile-avatar.png" className={classes.avatar} />
                    <Typography component="h1" variant="h5">
                        Update Password
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            error={passwordError !== ''}
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/signin" variant="body2">
                                    Have an account
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/signup" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    {/* <Copyright /> */}
                </Box>
            </Grid >
        </Grid >
    );
}
