import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { apolloClient } from '@apollo/client';

import { Grid, Chip, Typography, Divider, List, ListItem, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';

import User from '../../models/User'

import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { getActions, saveActions, useCurrentUser } from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';
import SmallProfile from '../Profile/SmallProfile';
import SmallParty from '../Party/SmallParty'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";



// am4core.useTheme(am4themes_animated);

// const [doActions, { data }] = useMutation(User.DOACTIONS);

class World extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            users: [],
            parties: []
        }
    }

    async componentDidMount() {

        let { data, loading } = await this.props.apolloClient.query({ query: User.COUNTRIES, variables: { code: this.props.currentUser.country } });
        let obj = JSON.parse(JSON.stringify(data.countries));

        let globalStatsRet = await this.props.apolloClient.query({ query: User.GLOBALSTATS });

        let map = am4core.create("mapchart", am4maps.MapChart);
        map.geodata = am4geodata_worldLow;
        map.projection = new am4maps.projections.Mercator();
        let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.useGeodata = true;
        polygonSeries.exclude = ["AQ"];
        polygonSeries.include = obj.map(c => c.code);
        // polygonSeries.include = ['MX', "US", "CA", "GB", "IE", "FR", "ES", "IT", "DE", "CO", "VZ", "PA", "GU", "AR", "SI", "GR", "HR", "BU", "PL", "NO", "SE", "FI", "DN", "BE", "LU", "CH", "PT", "RS", "NL", "RU", "BL", "UA", "CZ", "IS", "LT", "LV", "EE", "BY", ...obj.map(c => c.code)];

        polygonSeries.data = [];

        // {
        //     "id": "US",
        //     "name": "United States",
        //     "value": 100,
        //     "fill": am4core.color("#F05C5C")
        // }
        for (let country of obj) {
            polygonSeries.data.push({
                id: country.code,
                name: country.name,
                ...country
            });
        }

        console.log("Polygon series data :", polygonSeries.data);

        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name} : {stats.nbPlayers} players";
        // polygonTemplate.fill = am4core.color("#74B266");

        // Create hover state and set alternative fill color
        let hs = polygonTemplate.states.create("click");

        // Add infos about each country :


        this.mapChart = map;
        const resGlobalStats = JSON.parse(JSON.stringify(globalStatsRet.data.statsGlobal));

        this.setState({
            countries: obj,
            users: resGlobalStats.users,
            parties: resGlobalStats.parties
        });
    }

    componentWillUnmount() {
        if (this.mapChart) {
            this.mapChart.dispose();
        }
    }

    render() {

        const { currentUser, user, apolloClient, memory, onUpdateUser } = this.props;

        return (
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <h2>Leaderboard</h2>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Position</TableCell>
                                    <TableCell align="left">Player</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.users.map((user, idx) => (
                                    <TableRow key={'user'+idx}>
                                        <TableCell component="th" scope="row">
                                            #{idx + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            <SmallProfile currentUser={currentUser} memory={memory} user={user} apolloClient={apolloClient} onUpdateUser={onUpdateUser} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item md={12}>
                    <h2>Parties Leaderboard</h2>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Position</TableCell>
                                    <TableCell align="left">Party</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.parties.map((party, idx) => (
                                    <TableRow key={'party'+idx}>
                                        <TableCell component="th" scope="row">
                                            #{idx + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            <SmallParty currentUser={currentUser} memory={memory} party={party} apolloClient={apolloClient} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item md={12}>

                    <h2>World</h2>

                    <div id="mapchart" style={{ width: "100%", height: "500px" }}></div>

                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withTranslation()(World));