import React from 'react';
import { withStyles } from '@material-ui/styles';

import { apolloClient } from '@apollo/client';

import { Badge, AppBar, Grid, Typography, Divider, List, ListItem, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import User from '../../models/User'

import { withTranslation } from 'react-i18next'

import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';
import numeral from 'numeral'

// const [doActions, { data }] = useMutation(User.DOACTIONS);


const styles = (theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});


class CurrentActions extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            actions: []
        }
        this.nb = 0;

        this.computeCurrentActionProgress = this.computeCurrentActionProgress.bind(this);
    }

    async computeCurrentActionProgress(update) {

        clearTimeout(this.timeout);
        this.nb++;
        if (this.props.currentUser.currentActions.length === 0) return;

        // console.log("Compute actions progress ...", this.nb);
        const nowInterval = Interval.fromDateTimes(DateTime.fromISO(this.props.currentUser.currentActions[0].startDate), DateTime.now());
        const nowDuration = nowInterval.toDuration();
        const nowDiff = nowDuration.toMillis() / 1000;

        this.props.currentUser.currentActions.map(c => {

            const interval = Interval.fromDateTimes(DateTime.fromISO(c.startDate), DateTime.fromISO(c.endDate));
            const duration = interval.toDuration();
            const totalDuration = duration.toMillis() / 1000;

            // console.log("C :", c);
            // console.log(nowDiff, totalDuration, (nowDiff / totalDuration));

            if (nowDiff > totalDuration) {
                c.progress = 100;
            } else {
                c.progress = ((nowDiff / totalDuration) * 100).toFixed(0);
            }

            // console.log("progress :", c.progress);
        });

        if (update) {

            this.setState({
                currentActions: this.props.currentUser.currentActions
            });
        }

        if (this.props.currentUser.currentActions.filter(c => c.progress < 100).length > 0) {


            this.timeout = setTimeout(() => { this.computeCurrentActionProgress(true) }, 500);
        }

    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    async componentDidUpdate() {

        // console.log("Component did update ...", this.props);
        clearTimeout(this.timeout);
        // this.props.currentUser.currentActions = this.props.actions;
        if (this.props.currentUser.currentActions.length > 0) {
            this.computeCurrentActionProgress(false);
        }
    }

    async componentDidMount() {

        if (this.props.currentUser.currentActions.length > 0) {
            this.computeCurrentActionProgress(false);
        }

    }

    render() {

        let arr = [];

        if (this.props.currentUser && this.props.currentUser.currentActions.length > 0) {

            // console.log("Render current actions");
            arr.push(this.renderCurrentActions());

        } else if (this.props.configuredActions.length > 0) {

            // console.log("Render configured actions");
            arr.push(this.renderConfiguredActions());
        }

        if (this.props.currentUser.lastActions.length > 0) {
            arr.push(this.renderLastActions());
        }

        return arr;
    }

    renderConfiguredActions() {

        const { t, configuredActions, currentUser, deleteConfiguredAction, handleDoActions, classes } = this.props;

        if (!currentUser) return null;

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="configured-actions-content"
                    id="configured-actions-header"
                >
                    <div className={classes.column}>
                        <Typography className={classes.heading}>
                            <Badge overlap="circle" badgeContent={configuredActions.length} color="error" style={{ marginRight: 15 }}>
                                <SettingsIcon />
                            </Badge>
                            {t('UI_ACTION_POOL')}
                        </Typography>

                    </div>
                    <div className={classes.column}>
                        <Typography className={classes.secondaryHeading}>{t('UI_SEE_ACTIONS_TO_BE_LAUNCHED')}</Typography>
                    </div>
                    <div className={classes.column}>
                        <Typography className={classes.secondaryHeading} align="right">
                            <Button disabled={this.props.totalActionPointsConfigured > currentUser.level} variant="contained" color="secondary" onClick={handleDoActions}>
                                <PlayArrowIcon />
                            </Button>
                            ({this.props.totalActionPointsConfigured} / {currentUser.level})
                        </Typography>
                    </div>

                </AccordionSummary>
                <AccordionDetails className={classes.details}>

                    {(configuredActions.length > 0) ? (
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('UI_ACTION')}</TableCell>
                                        <TableCell align="right">{t('UI_MONEY')}</TableCell>
                                        <TableCell align="right">{t('UI_LIKES')}</TableCell>
                                        <TableCell align="right">{t('UI_PSX')}</TableCell>
                                        <TableCell align="right">{(t('UI_ACTION'))}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {configuredActions.map((configuredAction, idx) => (
                                        <TableRow key={"configured_action_"+idx}>
                                            <TableCell component="th" scope="row">
                                                [{this.props.actions.find(a => a.code === configuredAction.actionCode).actionPoints}]
                                            {t(configuredAction.actionCode + '_NAME')}
                                            </TableCell>
                                            <TableCell align="right">{numeral(configuredAction.resources.money).format('0.[000]a')} <AttachMoneyIcon style={{ fontSize: 12 }} /></TableCell>
                                            <TableCell align="right">{numeral(configuredAction.resources.like).format('0.[000]a')} <FavoriteIcon style={{ fontSize: 12 }} /></TableCell>
                                            <TableCell align="right">{numeral(configuredAction.resources.psx).format('0.[000]a')} {t('UI_PSX')}</TableCell>
                                            <TableCell align="right"><DeleteIcon style={{ fontSize: 12 }} onClick={() => { deleteConfiguredAction(idx) }} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (<Typography>{t('UI_ADD_ACTION_TO_POOL')}</Typography>)}
                </AccordionDetails>
                <Divider />
                <AccordionActions>

                </AccordionActions>
            </Accordion >
        );
    }

    renderLastActions() {

        const { t, configuredActions, currentUser, deleteConfiguredAction, handleDoActions, classes } = this.props;

        if (!currentUser) return null;

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="last-actions-content"
                    id="last-actions-header"
                >
                    <div className={classes.column}>
                        <Typography className={classes.heading}>
                        <Badge overlap="circle" badgeContent={currentUser.lastActions.length} color="error" style={{ marginRight: 15 }}>
                                <SettingsIcon />
                            </Badge>
                            {t('UI_LAST_ACTIONS')}
                        </Typography>
                    </div>
                    <div className={classes.column}>
                        <Typography className={classes.secondaryHeading}>{t('UI_SEE_LAST_RESULTS')}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="last actions table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('UI_ACTION')}</TableCell>
                                    <TableCell align="right">{t('UI_MONEY')}</TableCell>
                                    <TableCell align="right">{t('UI_LIKES')}</TableCell>
                                    <TableCell align="right">{t('UI_POINTS')}</TableCell>
                                    <TableCell align="right">{t('UI_PSX')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.currentUser.lastActions.map((lastAction, idx) => (
                                    <TableRow key={"last_action_"+idx}>
                                        <TableCell component="th" scope="row">
                                            {t(lastAction.code + '_NAME')}
                                        </TableCell>
                                        <TableCell align="right">-{numeral(lastAction.resources.money).format('0.[000]a')} +{numeral(lastAction.earnings.money).format('0.[000]a')}</TableCell>
                                        <TableCell align="right">-{(lastAction.resources.like) ? numeral(lastAction.resources.like).format('0.[000]a') : 0} +{numeral(lastAction.earnings.like).format('0.[000]a')}</TableCell>
                                        <TableCell align="right">-{(lastAction.resources.points) ? numeral(lastAction.resources.points).format('0.[000]a') : 0} +{numeral(lastAction.earnings.points).format('0.[000]a')}</TableCell>
                                        <TableCell align="right">-{(lastAction.resources.psx) ? numeral(lastAction.resources.psx).format('0.[000]a') : 0} +{numeral(lastAction.earnings.psx).format('0.[000]a')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        );
    }

    renderCurrentActions() {

        const { t, configuredActions, currentUser, classes } = this.props;

        // const { t, actions } = this.props;
        // console.log("Current actions : ", this.state);
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <div className={classes.column}>
                        <Typography className={classes.heading}>
                        <Badge overlap="circle" badgeContent={currentUser.currentActions.length} color="error" style={{ marginRight: 15 }}>
                                <SettingsIcon />
                            </Badge>
                            {t('UI_ACTION_IN_PROGRESS')}
                        </Typography>
                    </div>
                    <div className={classes.column}>
                        <Typography className={classes.secondaryHeading}>{t('UI_SEE_CURRENT_ACTIONS')}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('UI_ACTION')}</TableCell>
                                    <TableCell align="right">{t('UI_MONEY')}</TableCell>
                                    <TableCell align="right">{t('UI_LIKES')}</TableCell>
                                    <TableCell align="right">{t('UI_PSX')}</TableCell>
                                    <TableCell align="right">{t('UI_ACTION')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.currentUser.currentActions.map((currentAction, idx) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Box position="relative" display="inline-flex">
                                                <CircularProgress variant="determinate" value={currentAction.progress} />
                                                <Box
                                                    top={0}
                                                    left={0}
                                                    bottom={0}
                                                    right={0}
                                                    position="absolute"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="caption" component="div" color="textSecondary">
                                                        {this.props.actions.find(a => a.code === currentAction.code)?.actionPoints}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">{t(currentAction.code + '_NAME')}</TableCell>
                                        <TableCell align="right">{numeral(currentAction.resources.money).format('0.[000]a')} <AttachMoneyIcon style={{ fontSize: 12 }} /></TableCell>
                                        <TableCell align="right">{numeral(currentAction.resources.like).format('0.[000]a')} <FavoriteIcon style={{ fontSize: 12 }} /></TableCell>
                                        <TableCell align="right">{numeral(currentAction.resources.psx).format('0.[000]a')} {t('UI_PSX')}</TableCell >
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion >
        );
    }

}

export default withStyles(styles)(withTranslation()(CurrentActions));