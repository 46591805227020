import React, { Suspense, useState, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next'
import { ValuesOfCorrectTypeRule } from 'graphql';

import { Chip, Grid, Link, Avatar } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';

import { saveCurrentUser, disconnect } from '../../hooks/memory'
import { shortNumber } from '../../hooks/numberUtils'
import User from '../../models/User'
import PartyDialog from './Party'
import Flag from 'react-flagkit';

const classes = {};

class SmallParty extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            openPartyDialog: false
        }

        // const apolloClient = useApolloClient();

        if (!props.user) {

            // const { loading, error, data } = useQuery(User.USER, { variables: { id } });
            // const { data, loading } = await apolloClient.query({ query: User.USER, variables: { id } });
            // user = JSON.parse(JSON.stringify(data.user));
        }


        // console.log("SmallParty : Current ?", current, ", id:", id);
        // console.log("Res : ", loading, error, data);
    }
    
    render() {
        
        if (!this.props.party) return null;
        const party = (this.props.party) ? this.props.party : this.state.party;

        const handleCloseDialog = () => {
            this.props.history.goBack();
            this.setState({ openPartyDialog: false });
        }

        const handleOpenDialog = () => {

            console.log("handle open party :", party);
            this.props.history.push('#party-dialog-' + party.id);
            this.setState({ openPartyDialog: true });
        }


        // console.log("Props :", this.props);
        const { classes, t, currentUser, apolloClient } = this.props;


        // console.log("party :", party);
        
        const openDialog = window.location.href.indexOf('#party-dialog-' + party.id) > -1

        console.log("SmallParty props :", this.props);

        return (
            <Suspense fallback="Loading ...">
                <Chip
                    color="primary"
                    clickable
                    size="large"
                    onClick={handleOpenDialog}
                    icon={<Flag country={party.country.toUpperCase()} svg style={{ fontSize: 17, marginLeft: 10 }} />}
                    label={party.name.substr(0, 30) + ((party.name.length > 30)? '...': '')
                        + ' | ' + shortNumber(party.totalResources.points) + " pts"
                    }
                />
                <PartyDialog party={party} apolloClient={apolloClient} open={openDialog} handleClose={handleCloseDialog} currentUser={currentUser} onUpdateUser={this.props.onUpdateUser} />
            </Suspense>
        );
    }

}

export default withRouter(withStyles(classes)(withTranslation()(SmallParty)));
