import React, { useState, useEffect } from 'react';
import 'fontsource-roboto';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

import { Plugins } from "@capacitor/core";

import './App.css';

import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
// import client from './models/api'
import { setContext } from '@apollo/client/link/context';

import { Container } from '@material-ui/core';

// import TagManager from 'react-gtm-module'



import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/Home/Home';
import PublicProfile from './components/Profile/PublicProfile';
import SignUp from './components/Sign/SignUp';
import SignIn from './components/Sign/SignIn';
import PasswordForgotten from './components/Sign/PasswordForgotten';
import UpdatePassword from './components/Sign/UpdatePassword';
import Memory from './hooks/memory';

import Mentions from './components/Mentions';


import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import User from './models/User'

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: { loadPath: User.baseApiURL + '/assets/i18n/{{lng}}/translations.json' },
    fallbackLng: "en",
    interpolation: {
      escapeValue: true
    },
    react: {
      wait: true,
      useSuspense: false,
    }
  });


FirebaseAnalytics.initializeFirebase({
  apiKey: "AIzaSyCOxOFApaoD97JROyP5UTAk0rRdf6dTtxI",
  authDomain: "politicssimulator.firebaseapp.com",
  databaseURL: "https://politicssimulator.firebaseio.com",
  projectId: "politicssimulator",
  storageBucket: "politicssimulator.appspot.com",
  messagingSenderId: "960098860787",
  appId: "1:960098860787:web:a0c3eb7baed42516c5c186",
  measurementId: "G-0C6NNELML8"
});


function App() {

  const memory = new Memory();
  const [token, setToken] = useState(null);

  useEffect(async () => {
    let token = await memory.getToken();
    if (token) {

      console.log("[App.js] Token :", token);
      setToken(token);
    } else {
      setToken(false);
    }
  });


  const httpLink = createHttpLink({
    uri: User.baseApiURL + '/graphql',
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'x-token': (token) ? token : "",
      }
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  return (
    <Container>
      <ThemeProvider theme={darkTheme}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Switch>
              <Route path="/player/:id">
                <Home memory={memory} token={token} apolloClient={client} />
              </Route>
              <Route path="/signup">
                <SignUp setToken={memory.saveToken} memory={memory} setToken={setToken} token={token} />
              </Route>
              <Route path="/password_forgotten">
                <PasswordForgotten setToken={memory.saveToken} memory={memory} setToken={setToken} token={token} />
              </Route>
              <Route path="/update_password/:authToken">
                <UpdatePassword setToken={memory.saveToken} memory={memory} setToken={setToken} token={token} />
              </Route>
              <Route path="/signin">
                <SignIn setToken={memory.saveToken} memory={memory} setToken={setToken} token={token} />
              </Route>
              <Route path="/profile/:id" render={(props => (<PublicProfile memory={memory} token={token} apolloClient={client} {...props} />))} />
              <Route path="/home">
                <Home memory={memory} token={token} apolloClient={client} />
              </Route>
              <Route exact path="/mentions">
                <Mentions />
              </Route>

              <Route path="/" render={() => {
                return (
                  token ?
                    <Redirect to="/home/actions" /> :
                    <Redirect to="/signin" />
                )
              }}
              />
            </Switch>
          </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </Container>
  );
}

export default App;
