import React from 'react';
import { withStyles } from '@material-ui/styles';

import { apolloClient } from '@apollo/client';

import Collapse from '@material-ui/core/Collapse';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

import { AppBar, Grid, Typography, Divider, List, ListItem, Badge, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import User from '../../models/User'

import { withTranslation } from 'react-i18next'

import { getActions, saveActions, useCurrentUser } from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';
import numeral from 'numeral'

let classes = (theme) => ({
    
});

class AttackConfiguration extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        }
    }

    componentDidMount() {

        const { attack } = this.props;
        this.setState({
            money: attack.minCost.money,
            like: attack.minCost.like,
            psx: attack.minCost.psx,
        });
    }

    render() {

        const { t, attack, user, currentUser, onHandleClose, onHandleAttack, apolloClient } = this.props;

        const handleAttackLaunch = async () => {

            // Send an attack :
            const ret = await apolloClient.mutate({ mutation: User.ATTACKUSER, variables: { attackedId: user.id, attackCode: attack.code, resources: { money: this.state.money, like: this.state.like, psx: this.state.psx } } });
            onHandleAttack();
        }

        const setActionRes = (k, v) => {
            this.setState({ [k]: v });
        }

        return (<Dialog open={true} onClose={onHandleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('UI_CONFIGURE_ATTACK')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('UI_CONFIGURE_ATTACK_TXT')}
                </DialogContentText>
                <Typography>{t('UI_MONEY')}</Typography>
                <Slider
                    defaultValue={attack.minCost.money}
                    onChangeCommitted={(e, v) => setActionRes('money', v)}
                    getAriaValueText={(v) => v}
                    aria-labelledby="discrete-slider-small-steps"
                    step={100}
                    min={attack.minCost.money}
                    max={Math.min(attack.maxCost.money, currentUser.resources.money)}
                    valueLabelDisplay="auto"
                />
                <Typography>{t('UI_LIKES')}</Typography>
                <Slider
                    defaultValue={attack.minCost.like}
                    onChangeCommitted={(e, v) => setActionRes('like', v)}
                    getAriaValueText={(v) => v}
                    disabled={Math.min(attack.maxCost.like, currentUser.resources.like) < 1}
                    aria-labelledby="discrete-slider-small-steps"
                    step={10}
                    min={attack.minCost.like}
                    max={Math.min(attack.maxCost.like, currentUser.resources.like)}
                    valueLabelDisplay="auto"
                />
                <Typography>{t('UI_PSX')}</Typography>
                <Slider
                    defaultValue={0}
                    onChangeCommitted={(e, v) => setActionRes('psx', v)}
                    disabled={Math.min(100, currentUser.resources.psx) < 1}
                    getAriaValueText={(v) => v}
                    aria-labelledby="discrete-slider-small-steps"
                    step={1}
                    min={0}
                    max={Math.min(100, currentUser.resources.psx)}
                    valueLabelDisplay="auto"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onHandleClose} color="primary">
                    {t('UI_CANCEL')}
                </Button>
                <Button variant="contained" onClick={handleAttackLaunch} color="primary">
                    {t('UI_ATTACK')}
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

export default withStyles(classes)(withTranslation()(AttackConfiguration));