import React from 'react';
import { withStyles } from '@material-ui/styles';

import { apolloClient } from '@apollo/client';

import Collapse from '@material-ui/core/Collapse';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

import { AppBar, Grid, Typography, Divider, List, ListItem, Badge, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import User from '../../models/User'


import { withTranslation } from 'react-i18next'

import Memory from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';
import numeral from 'numeral'
import AttackConfiguration from './AttackConfiguration';

let classes = (theme) => ({
    fab: {
        position: 'fixed',
        bottom: 65,
        right: 30,
    },
});

class Attack extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            attacks: []
        }
    }

    async componentDidMount() {

        const arr = await this.props.memory.getAttacks()
        this.setState({ attacks: arr });
    }

    render() {

        let { attacks } = this.state;
        const { t, user, currentUser, apolloClient, onUpdateUser } = this.props;

        console.log("Attacks :", attacks);

        const handleCloseAttackConfiguration = () => {
            this.setState({ configureAttack: null });
        }

        const handleAttackConfiguration = () => {
            onUpdateUser();
            this.setState({ configureAttack: null });
        }

        const onHandleAttack = (a) => {
            this.setState({ configureAttack: a });
        }

        const userCanDo = (attack) => {
            // Check resources :
            if (!User.hasEnoughResources({ resources: currentUser.resources }, attack.minCost)) {
                return false;
            }
            return true;
        }

        return (<Grid container>
            { attacks.map(a => (<Grid item md={12}>
                <Card>
                    <CardHeader title={t('UI_' + a.code + '_TITLE')} subheader={t('UI_' + a.code + '_SUBHEADER')} />
                    <CardContent>
                        <Typography>{t('UI_' + a.code + '_TXT')}</Typography>
                        <Typography>{t('UI_SUCCESS_CHANCES')} : {Math.floor(a.probability * 100)}%</Typography>
                        <Grid container style={{ display: 'flex' }} spacing={2}>
                            <Grid item xs={6} md={3}>
                                {t('UI_FROM')} : {numeral(a.minCost.money).format('0.[000]a')} <AttachMoneyIcon style={{ fontSize: 12 }} />
                            </Grid>
                            {(a.minCost.like) ? (
                            <Grid item xs={6} md={3}>
                                {numeral(a.minCost.like).format('0.[000]a')} <FavoriteIcon style={{ fontSize: 12 }} />
                            </Grid>) : null}
                            {(a.minCost.actionPoints) ? (
                            <Grid item xs={6} md={3}>
                                {numeral(a.minCost.points).format('0.[000]a')} <OfflineBoltIcon style={{ fontSize: 12 }} />
                            </Grid>) : null}
                            <Grid item xs={6} md={3}>
                                <AccessAlarmIcon style={{ fontSize: 12 }} /> {new Date(a.duration * 1000).toISOString().substr(11, 8)}
                            </Grid>
                        </Grid>

                        <Grid container style={{ display: 'flex' }} spacing={2}>
                            <Grid item md={6}>
                                Player should loose :
                                {
                                    Object.keys(a.losts).map(l => (<div>
                                        { a.losts[l] * 100} %
                                        { (l === 'money') ? (<AttachMoneyIcon style={{ fontSize: 12 }} />) : (l === 'like') ? (<FavoriteIcon style={{ fontSize: 12 }} />) : null}
                                    </div>))
                                }
                            </Grid>
                            <Grid item md={6}>
                                You should win (portion of the player's lost) :
                                {
                                    Object.keys(a.earnings).map(earn => (<div>
                                        { a.earnings[earn] * 100} %
                                        { (earn === 'money') ? (<AttachMoneyIcon style={{ fontSize: 12 }} />) : (earn === 'like') ? (<FavoriteIcon style={{ fontSize: 12 }} />) : null}
                                    </div>))
                                }
                            </Grid>
                        </Grid>

                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="secondary" onClick={() => { onHandleAttack(a) }} disabled={(!userCanDo(a))}>{t('UI_ATTACK')}</Button>
                    </CardActions>
                </Card>
            </Grid>))}
            { (this.state.configureAttack) ? (<AttackConfiguration attack={this.state.configureAttack} user={user} currentUser={currentUser} apolloClient={apolloClient} onHandleClose={handleCloseAttackConfiguration} onHandleAttack={handleAttackConfiguration} />) : null}
        </Grid>);
    }
}

export default withStyles(classes)(withTranslation()(Attack));