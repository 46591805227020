import React from 'react';
import { withStyles } from '@material-ui/styles';

import { apolloClient } from '@apollo/client';

import { Chip, AppBar, Grid, Typography, Divider, List, ListItem, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
// import Transition from '@material-ui/core/Transition';
// import Toolbar from '@material-ui/core/Toolbar';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';


import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';


import User from '../../models/User'
import SmallProfile from './SmallProfile'
import Attack from '../Attack/Attack'
import SmallParty from '../Party/SmallParty'

import { withTranslation } from 'react-i18next'

import { getActions, saveActions, useCurrentUser } from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';
import numeral from 'numeral'

import Flag from 'react-flagkit'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const classes = (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    appBar: {
        position: 'relative',
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class Profile extends React.Component {

    constructor(props) {

        super(props);
        this.state = {};
    }


    render() {

        const { t, classes, currentUser, apolloClient, onUpdateUser, user, open, handleClose, memory } = this.props;

        if (!user || !apolloClient) return null;

        // console.log("Dialog party :", party);

        console.log("Profile props :", this.props);

        return (
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {user.username}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={user.username + "'s profile"} subheader="" />
                            <CardContent>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Level</TableCell>
                                                <TableCell align="right" style={{ paddingTop: 50 }}>
                                                    {user.level}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Country</TableCell>
                                                <TableCell align="right">
                                                    <Flag country={user.country} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Party</TableCell>
                                                <TableCell align="right">{(user.party) ? <SmallParty currentUser={user} apolloClient={apolloClient} onUpdateUser={onUpdateUser} party={user.party} /> : 'Indep'}</TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Likes</TableCell>
                                                <TableCell align="right">
                                                    {numeral(user.resources.like).format('[000,00]0')}
                                                    <FavoriteIcon style={{ fontSize: 14, marginRight: 5 }} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Dislikes</TableCell>
                                                <TableCell align="right">
                                                    {numeral(user.resources.dislike).format('[000,00]0')}
                                                    <ThumbDownIcon style={{ fontSize: 14, marginRight: 5 }} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Points</TableCell>
                                                <TableCell align="right">
                                                    {numeral(user.resources.points).format('[000,00]0')}
                                                    <OfflineBoltIcon style={{ fontSize: 14, marginRight: 5 }} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Values</TableCell>
                                                <TableCell align="right">
                                                    {user.values ? user.values.map(v => { return t(v) }).join(', ') : 'No values'}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={"What can I do against this guy ?"} subheader="" />
                            <CardContent>
                                {/* Metrics, infos sur les valeurs ... */}
                                <Attack user={user} currentUser={currentUser} memory={memory} apolloClient={apolloClient} onUpdateUser={onUpdateUser} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }

}

export default withStyles(classes)(withTranslation()(Profile));