import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Chip, AppBar, Grid, Typography, Divider, List, ListItem, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
// import Transition from '@material-ui/core/Transition';
// import Toolbar from '@material-ui/core/Toolbar';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';


import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';


import User from '../../models/User'
import SmallParty from './SmallParty'
import SmallProfile from '../Profile/SmallProfile'

import { withTranslation } from 'react-i18next'

import { getActions, saveActions, useCurrentUser } from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';
import numeral from 'numeral'

import Flag from 'react-flagkit';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const classes = (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    appBar: {
        position: 'relative',
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class Party extends React.Component {

    constructor(props) {

        super(props);
        this.getPartyStats = this.getPartyStats.bind(this);
        this.state = {
            partyId: false,
            stats: {
                users: []
            }
        }
    }

    async componentDidMount() {
            // await this.getPartyStats();
    }

    async componentDidUpdate(prevProps) {

        // console.log("Update component dialog party :", this.props, prevProps);
        if (this.props.open && !prevProps.open) {
            await this.getPartyStats();
        }
    }

    async getPartyStats() {
        console.log("Props :", this.props);
        const { party, apolloClient } = this.props;
        if (!party || !apolloClient) return [];
        const { data, loading } = await apolloClient.query({ query: User.PARTYSTATS, variables: { party: party.id }, fetchPolicy: "no-cache" });
        let obj = JSON.parse(JSON.stringify(data.statsParty));
        console.log("Obj statsParty :", obj);
        this.setState({ stats: obj });
    }

    render() {

        const { t, apolloClient, onUpdateUser, classes, currentUser, party, open, handleClose } = this.props;

        if (!party || !currentUser) return null;

        const handleSetMember = async () => {
            if (!apolloClient) {

                await apolloClient.mutate({ mutation: User.UPDATEUSER, variables: { party: party.id } });
                await onUpdateUser();
                handleClose();
            }
        }

        // console.log("Dialog party :", party);

        return (
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Party details
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title="Party name" subheader="" />
                            <CardContent>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Name</TableCell>
                                                <TableCell align="right">
                                                    {party.name}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Country</TableCell>
                                                <TableCell align="right">
                                                    <Flag country={party.country.toUpperCase()} style={{ fontSize: 17, marginLeft: 10 }} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Number of members</TableCell>
                                                <TableCell align="right">
                                                    {party.totalResources.nbMembers}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Total points</TableCell>
                                                <TableCell align="right">
                                                    {party.totalResources.points}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Total likes</TableCell>
                                                <TableCell align="right">
                                                    {party.totalResources.like}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Total dislikes</TableCell>
                                                <TableCell align="right">
                                                    {party.totalResources.dislike}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="">
                                                <TableCell component="th" scope="row">Values</TableCell>
                                                <TableCell align="right">
                                                    {party.values.map(v => t(v)).join(', ')}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                            <CardActions>
                                {(!currentUser.party) ? (<Button variant="contained" color="primary" onClick={handleSetMember}>
                                    Become member
                                </Button>) : null}

                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title="Leaderboard" subheader="" />
                            <CardContent>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Position</TableCell>
                                                <TableCell align="left">Player</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.stats.users.map((user, idx) => (
                                                <TableRow key={user.idx}>
                                                    <TableCell component="th" scope="row">
                                                        #{idx + 1}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <SmallProfile user={user} memory={this.props.memory} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }

}

export default withStyles(classes)(withTranslation()(Party));