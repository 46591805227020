import React, { Suspense, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { apolloClient } from '@apollo/client';

import { Grid, Chip, Typography, Divider, List, ListItem, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';

import User from '../../models/User'
import SmallParty from '../Party/SmallParty'

import { withTranslation } from 'react-i18next'

import { getActions, saveActions, useCurrentUser } from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';

import SmallProfile from '../Profile/SmallProfile';

import { Share } from '@capacitor/share';

import Flag from 'react-flagkit';

// const [doActions, { data }] = useMutation(User.DOACTIONS);

let classes = {
    margin: {
        height: 60,
    },
};

class CountryPage extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            country: { stats: { values: [] } },
            parties: [],
            users: []
        }

        this.findParties = this.findParties.bind(this);

        this.findParties();
    }

    async componentDidMount() {

        if (!this.props.currentUser) return;

        let { data, loading } = await this.props.apolloClient.query({ query: User.COUNTRY, variables: { code: this.props.currentUser.country } });
        let obj = JSON.parse(JSON.stringify(data.country));


        let retCountryStats = await this.props.apolloClient.query({ query: User.COUNTRYSTATS, variables: { countryCode: this.props.currentUser.country } });
        this.setState({
            country: obj,
            users: JSON.parse(JSON.stringify(retCountryStats.data.statsCountry)).users
        });
    }

    async findParties() {
        const { currentUser } = this.props;
        if (!currentUser) return [];
        const { data, loading } = await this.props.apolloClient.query({ query: User.PARTYS, variables: { country: currentUser.country, nb: 50 }, fetchPolicy: "no-cache" });
        let obj = JSON.parse(JSON.stringify(data.partys));
        // console.log("Obj:", obj);
        this.setState({ parties: obj });
    }

    render() {

        const { currentUser, user, t, apolloClient, memory, onUpdateUser } = this.props;

        const handleInvite = async () => {
            await Share.share({
                title: 'Join me on Politics Simulator',
                text: 'A cynical (?) game about politics. Makes it fun to talk "real" politics ...',
                url: 'https://www.politics-simulator.com/signup#' + currentUser.id,
                dialogTitle: 'Invite friends'
            });

        }

        const lastMetric = (this.state.country.metric && this.state.country.metric.length) ? this.state.country.metric[this.state.country.metric.length - 1] : { generalTaxRate: 0.07 }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {/* Metrics about my country */}
                    <Paper style={{ padding: '15px' }}>
                        <h2>Identity of {this.state.country.name}</h2>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Flag</TableCell>
                                        <TableCell align="right">
                                            <Suspense fallback="Loading ...">
                                                {this.state.country.code? (<Flag country={this.state.country.code.toUpperCase()} />) : null }
                                            </Suspense>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">President</TableCell>
                                        <TableCell align="right">Not enough players for that. Your country is managed by banks. It's quite identical to real life.</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">President's values</TableCell>
                                        <TableCell align="right">Money, money, money</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">General tax rate</TableCell>
                                        <TableCell align="right">{parseInt(lastMetric.generalTaxRate * 100)}%</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Number of players</TableCell>
                                        <TableCell align="right">{this.state.country.stats.nbPlayers}</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Most represented values</TableCell>
                                        <TableCell align="right">{this.state.country.stats.values.sort((a, b) => { return (a.nb > b.nb) ? -1 : 1 }).map(v => (<Typography>{t(v.key)} x {v.nb}</Typography>))}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                </Grid>
                <Grid item xs={12} md={6}>
                    <h2>Elections </h2>
                    {/* Metrics about my country */}
                    <Typography>
                        Your country does not count enough players to organize elections.
                        Do you really think this is a game ?
                    </Typography>
                    <Button color="primary" variant="contained" onClick={handleInvite}>
                        Invite friends to Politics Simulator
                    </Button>



                </Grid>
                <Grid item xs={12} md={6}>
                    <h2>Events</h2>

                    Politics is demagogy, you will have to take position about events.
                    <Chip
                        color="secondary"
                        label="Coming soon"
                    />
                    {/* <Paper style={{ backgroundColor: '#444', minWidth: '200px', minHeight: '200px', color: 'yellow' }}>
                    <p style={{ margin: 'auto' }}>Coming Soon</p>
                </Paper> */}

                </Grid>
                <Grid item xs={12} md={6}>
                    <h2>Media</h2>

                    <Typography>
                        Medias are sensitive little pets. They need attention, money and ... money again. If you are nice with them, they can help you ... or destruct you.
                        You can buy parts of theses medias, this will decrease impact of media attacks on you.
                        They won't be too rough with their owner ;)
                        </Typography>

                    <Chip
                        color="secondary"
                        label="Coming soon"
                    />

                </Grid>

                <Grid item xs={12} md={6}>
                    <h2>Commissions</h2>
                    {/* Les 10 plus gros partis politiques.
            Les 10 plus gros joueurs. */}
                    <Typography>
                        The best way to earn money and influence is to be part of commissions.
                        The president chooses players to be part of them. They can create new commissions.
                    </Typography>
                    <Chip
                        color="secondary"
                        label="Coming soon"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <h2>Partys</h2>
                    {/* Les 10 plus gros partis politiques. */}
                    <ul>
                        {this.state.parties.map(p => (<li style={{ margin: 4 }}><SmallParty currentUser={currentUser} apolloClient={apolloClient} onUpdateUser={onUpdateUser} party={p} /></li>))}
                    </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                    <h2>Leaderboard</h2>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Position</TableCell>
                                    <TableCell align="left">Player</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.users.map((user, idx) => (
                                    <TableRow key={user.idx}>
                                        <TableCell component="th" scope="row">
                                            #{idx + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            <SmallProfile currentUser={currentUser} memory={memory} user={user} apolloClient={apolloClient} onUpdateUser={onUpdateUser} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </Grid>
            </Grid>
        );
    }
}

export default withTranslation()(CountryPage);