import React from 'react';
import { withStyles } from '@material-ui/styles';

import "@capacitor-community/firebase-analytics";
import { Plugins } from "@capacitor/core";

import { apolloClient } from '@apollo/client';

import Collapse from '@material-ui/core/Collapse';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

import { AppBar, Grid, Typography, Divider, List, ListItem, Badge, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';


import User from '../../models/User'
import CurrentActions from './CurrentActions'

import { withTranslation } from 'react-i18next'

import Memory from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';
import numeral from 'numeral'
// const [doActions, { data }] = useMutation(User.DOACTIONS);

// import { AdMob, AdOptions, AdLoadInfo, RewardAdPluginEvents, AdMobRewardItem, InterstitialAdPluginEvents } from '@capacitor-community/admob';

const { FirebaseAnalytics } = Plugins;
let classes = (theme) => ({
    fab: {
        position: 'fixed',
        bottom: 65,
        right: 30,
    },
});

class Actions extends React.Component {

    constructor(props) {

        super(props);

        this.initActions = this.initActions.bind(this);

        this.state = {
            currentActions: [],
            actions: [],
            actionResMoney: null,
            actionResLike: null,
            actionResPSX: null,
            configuredAction: { minCost: { money: 0, like: 0 }, maxCost: { money: 0, like: 0 } },
            configuredActions: [],
            openConfigureAction: false,
            launchActions: null,
            doActions: null,
            totalActionPointsConfigured: 0
        }



    }

    componentDidMount() {

        this.initActions();
        this.initAttacks();
        this.initOptions();

        // AdMob.initialize({
        //     requestTrackingAuthorization: true,
        //     testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
        //     initializeForTesting: true,
        // });


        // this.showRewardedAd();
    }

    // async showRewardedAd() {

    //     AdMob.addListener(RewardAdPluginEvents.Rewarded, (rewardItem) => {
    //         // Subscribe user rewarded
    //         console.log(rewardItem);

    //     });

    //     const options = {
    //         adId: 'ca-app-pub-0138959010320089/5662936081',
    //         // isTesting: true
    //         // npa: true
    //         userId: 'mon_user_id',
    //         customData: 'Mon_custom_data'
    //     };
    //     await AdMob.prepareRewardVideoAd(options);
    //     const rewardItem = await AdMob.showRewardVideoAd();
    //     console.log("Reward item :", rewardItem)
    // }

    async initActions() {

        let memoryActions = await this.props.memory.getActions();

        // console.log("Memory actions :", memoryActions);
        // if (!memoryActions || Math.random() < 0.34) {
        const response = await fetch(User.baseApiURL + '/assets/data/actions.json');
        let data = await response.json();
        // console.log("Response :", data);

        if (this.props.currentUser.options.find(o => o === 'CAR_1')) {

            data = data.map(a => { a.duration = a.duration * 0.9; return a; })
        }

        await this.props.memory.saveActions(data);
        this.setState({ actions: data });
        // } else {

        //     if (this.props.currentUser.options.find(o => o === 'CAR_1')) {

        //         memoryActions = memoryActions.map(a => { a.duration = a.duration * 0.9; return a; })
        //     }
        //     this.setState({ actions: memoryActions });
        // }
    }

    async initAttacks() {
        let memoryAttacks = await this.props.memory.getAttacks();
        // console.log("Memory Attacks :", memoryAttacks);
        // if (!memoryAttacks || Math.random() < 0.99) {
        const response = await fetch(User.baseApiURL + '/assets/data/attacks.json');
        const data = await response.json();
        // console.log("Response :", data);
        await this.props.memory.saveAttacks(data);
        this.setState({ attacks: data });
        // } else {
        //     this.setState({ attacks: memoryAttacks });
        // }
    }

    async initOptions() {
        let memoryOptions = await this.props.memory.getOptions();
        // console.log("Memory Attacks :", memoryOptions);
        if (!memoryOptions) {


            const { data, loading } = await this.props.apolloClient.query({ query: User.OPTIONS });
            let options = JSON.parse(JSON.stringify(data.options));

            // console.log("Response :", data);
            await this.props.memory.saveOptions(options);
            this.setState({ options: options });
        } else {
            this.setState({ options: memoryOptions });
        }
    }


    render() {

        //{ currentUser, props.onUpdateUser }

        // if (!this.state.actions || this.state.actions.length === 0) {
        //     console.log("No actions :", this.state.actions)
        //     this.getActions();
        // } else {
        //     console.log("Exist actions :", this.state.actions)
        // }

        const { configuredAction, configuredActions } = this.state;
        const { classes, currentUser } = this.props;
        const t = this.props.t;
        const { PushNotifications } = Plugins;

        // console.log("Actions render :", this.state.actions);


        if (!this.props.currentUser) return null;



        if (this.props.currentUser && !this.props.currentUser.messagingToken && !this.state.alreadyMessagingRegistered) {

            // Register then !!!
            if (PushNotifications) {

                PushNotifications.register();
    
                // On succcess, we should be able to receive notifications
                PushNotifications.addListener('registration',
                    (token) => {
                        // alert('Push registration success, token: ' + token.value);
    
                        // Send token to backend :
                        this.props.apolloClient.mutate({ mutation: User.UPDATEUSER, variables: { messagingToken: token.value } });
                    }
                );
    
                // Some issue with your setup and push will not work
                PushNotifications.addListener('registrationError',
                    (error) => {
                        // alert('Error on registration: ' + JSON.stringify(error));
                    }
                );
    
                // Show us the notification payload if the app is open on our device
                PushNotifications.addListener('pushNotificationReceived',
                    (notification) => {
                        // alert('Receive on Notif: ' + notification.title + ", " + notification.body);
                    }
                );
    
                // Method called when tapping on a notification
                PushNotifications.addListener('pushNotificationActionPerformed',
                    (notification) => {
                        // alert('Action on Notif: ' + notification.title + ", " + notification.body);
    
                    }
                );
                
                this.setState({ alreadyMessagingRegistered: true })
            }

        }

        // console.log("Current user resources :", this.props.currentUser.resources);
        let freeResources = JSON.parse(JSON.stringify(this.props.currentUser.resources));
        // console.log("Free resources :", freeResources);
        for (let confAction of configuredActions) {
            // console.log("Conf action :", confAction);
            for (let k in freeResources) {
                if (confAction.resources[k]) {
                    // console.log("K:", k);
                    // console.log("Passage de ", k, freeResources[k], confAction.resources[k]);
                    freeResources[k] = freeResources[k] - confAction.resources[k];

                }
            }
        }

        // console.log("[Actions][render] Current user :", this.props.currentUser);

        const setActionResMoney = (v) => { this.setState({ actionResMoney: v }) }
        const setActionResLike = (v) => { this.setState({ actionResLike: v }) }
        const setActionResPSX = (v) => { this.setState({ actionResPSX: v }) }

        // const { currentUser, setCurrentUser } = useCurrentUser();

        const handleDoActions = async e => {
            // console.log("handleDoActions : CLICKED ");
            e.preventDefault();
            if (this.state.totalActionPointsConfigured <= this.props.currentUser.level) {

                FirebaseAnalytics.logEvent({
                    name: "do_actions",
                    params: {
                        content_type: "image",
                        content_id: "do_actions",
                        items: configuredActions,
                    },
                });

                const ret = await this.props.apolloClient.mutate({ mutation: User.DOACTIONS, variables: { actions: configuredActions } });
                updateUser();
                this.setState({ configuredActions: [] });
            } else {
                // Show error ?
            }
        }

        const handleClickOpen = (a) => {
            // console.log("Open action ", a);
            this.setState({
                configuredAction: a,
                actionResMoney: a.minCost.money,
                actionResLike: a.minCost.like,
                actionResPSX: 0,
                openConfigureAction: true,
            });
        };

        const handleClose = () => {
            // setOpenConfigureAction(false);
            this.setState({
                openConfigureAction: false
            });
        };

        const deleteConfiguredAction = async (idx) => {
            let arr_ = JSON.parse(JSON.stringify(this.state.configuredActions));
            arr_.splice(idx, 1);
            // setConfiguredActions(arr_);
            let totalActionPointsConfigured = 0;
            arr_.map(a => { totalActionPointsConfigured += this.state.actions.find(action => action.code === a.actionCode).actionPoints });
            this.setState({ configuredActions: arr_, totalActionPointsConfigured });
        }

        const updateTotalActionPoints = () => {
            // console.log("Compute total action points configured ...");
            let totalActionPointsConfigured = 0;
            this.state.configuredActions.map(a => { totalActionPointsConfigured += this.state.actions.find(action => action.code === a.actionCode).actionPoints });
            this.setState({
                totalActionPointsConfigured: totalActionPointsConfigured
            });

        }

        const handleAddToList = () => {

            FirebaseAnalytics.logEvent({
                name: "open_add_to_list",
                params: {
                    content_id: "add_to_list"
                },
            });

            // console.log("Action à ajouter : ", this.state.actionResMoney, this.state.actionResLike, this.state.actionResPSX, this.state.configuredAction);
            let arr = this.state.configuredActions;
            arr.push({
                actionCode: this.state.configuredAction.code,
                resources: {
                    money: this.state.actionResMoney,
                    like: this.state.actionResLike,
                    psx: this.state.actionResPSX
                }
            });

            this.setState({
                configuredActions: arr
            });
            handleClose();
            updateUser();
        }

        const updateUser = async () => {

            // console.log("Ask for a user update ...");
            await this.props.onUpdateUser();
            updateTotalActionPoints();

        }

        // Program an update when the first action will end :
        if (this.props.currentUser.currentActions.length > 0) {
            for (let i = 0; i < this.props.currentUser.currentActions.length; i++) {
                const nowInterval = Interval.fromDateTimes(DateTime.now(), DateTime.fromISO(this.props.currentUser.currentActions[i].endDate));
                const nowDuration = nowInterval.toDuration();
                const millisToEndOfFirstAction = nowDuration.toMillis();
                if (millisToEndOfFirstAction && millisToEndOfFirstAction > 0) {
                    // console.log("Set timeout to :", millisToEndOfFirstAction, 'ms');
                    setTimeout(() => { updateUser(); /*console.log("Update of user asked ...");*/ }, millisToEndOfFirstAction + 750);
                } else {

                    setTimeout(() => { updateUser(); /*console.log("Update of user asked ...");*/ }, 150);
                    break;
                }
            }
        }

        const userCanDo = (action) => {

            // Check resources :
            if (!User.hasEnoughResources({ resources: freeResources }, action.minCost)) {
                // console.log("Has not enough resources", freeResources, action.minCost);
                return false;
            }

            if (this.props.currentUser.currentActions.length > 0) {
                // console.log("Current actions in progress ...");
                return false;
            }

            // Check configured actions on level :
            if ((this.state.totalActionPointsConfigured + action.actionPoints) > currentUser.level) {
                // console.log("Has not enough level ...", this.state.totalActionPointsConfigured, action.actionPoints, currentUser.level);
                return false;
            }

            if (this.props.currentUser.options.filter(o => action.needs.indexOf(o) > -1).length !== action.needs.length) {
                return false;
            }

            return true;
        }

        return (<Collapse in={true}>
            <CurrentActions actions={this.state.actions} currentUser={this.props.currentUser} configuredActions={configuredActions} totalActionPointsConfigured={this.state.totalActionPointsConfigured} deleteConfiguredAction={deleteConfiguredAction} handleDoActions={handleDoActions} />

            { (this.props.currentUser.resources.points < 2) ? (
                <Card>
                    <CardHeader title={t('UI_WHAT_DOING_HERE')} />
                    <CardContent>
                        {t('UI_WHAT_DOING_HERE_TXT')}
                    </CardContent>
                </Card>
            ) : null}

            <Grid container spacing={2}>

                <Grid item md={12}>
                    <h2>{t('UI_ACTIONS')}</h2>

                    {this.state.actions.map(a => (
                        <Card style={{ margin: 10 }}>
                            <CardActionArea>
                                <CardHeader
                                    avatar={(<Avatar>{a.actionPoints}</Avatar>)}
                                    title={t(a.code + '_NAME')}
                                    subheader={t('UI_DIFFICULTY') + " : " + a.difficulty + "/3" + ((a.difficulty > 1) ? ' (' + t('UI_CAN_FAIL') + ')' : '')}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {t(a.code + '_DESC')}
                                        <Grid container style={{ display: 'flex' }} spacing={2}>
                                            <Grid item xs={6} md={3}>
                                                {t('UI_FROM')} : {numeral(a.minCost.money).format('0.[000]a')} <AttachMoneyIcon style={{ fontSize: 12 }} />
                                            </Grid>
                                            {(a.minCost.like) ? (<Grid item xs={6} md={3}>
                                                {numeral(a.minCost.like).format('0.[000]a')} <FavoriteIcon style={{ fontSize: 12 }} />
                                            </Grid>) : null}
                                            {(a.minCost.actionPoints) ? (<Grid item xs={6} md={3}>
                                                {numeral(a.minCost.points).format('0.[000]a')} <OfflineBoltIcon style={{ fontSize: 12 }} />
                                            </Grid>) : null}
                                            <Grid item xs={6} md={3}>
                                                <AccessAlarmIcon style={{ fontSize: 12 }} /> {new Date(a.duration * 1000).toISOString().substr(11, 8)}
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ display: 'flex' }} spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                {t('UI_REWARDS')} :
                                                {(a.rewardIndex.money) ? (<AttachMoneyIcon style={{ fontSize: 12 }} />) : null}
                                                {(a.rewardIndex.like) ? (<FavoriteIcon style={{ fontSize: 12 }} />) : null}
                                                {(a.rewardIndex.dislike) ? (<ThumbDownIcon style={{ fontSize: 12 }} />) : null}
                                                {(a.rewardIndex.points) ? (<OfflineBoltIcon style={{ fontSize: 12 }} />) : null}
                                            </Grid>
                                        </Grid>
                                        {(a.needs.length > 0) ? (<Grid container style={{ display: 'flex' }} spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                {t('UI_NEEDS')} :
                                                {a.needs.map(need => (<Typography>{(this.props.currentUser.options.indexOf(need) > -1) ? (
                                                    <CheckIcon style={{ fontSize: '14px', color: 'green' }} />
                                                ) : (
                                                    <NotInterestedIcon style={{ fontSize: '14px', color: 'red' }} />
                                                )} {t(need)}</Typography>))}
                                            </Grid>
                                        </Grid>) : null}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>

                                <Fade in={userCanDo(a)}>
                                    <Button variant="contained" onClick={() => handleClickOpen(a)} size="small" color="primary" disabled={(!userCanDo(a))}>
                                        {t('UI_ADD')}
                                    </Button>
                                </Fade>

                            </CardActions>
                        </Card>
                    ))}

                    <Dialog open={this.state.openConfigureAction} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{t('UI_CONFIGURE')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('UI_CONFIGURE_TXT')}
                            </DialogContentText>
                            <Typography>{t('UI_MONEY')}</Typography>
                            <Slider
                                defaultValue={configuredAction.minCost.money}
                                onChangeCommitted={(e, v) => setActionResMoney(v)}
                                getAriaValueText={(v) => v}
                                aria-labelledby="discrete-slider-small-steps"
                                step={100}
                                min={configuredAction.minCost.money}
                                max={Math.min(configuredAction.maxCost.money, freeResources.money)}
                                valueLabelDisplay="auto"
                            />
                            <Typography>{t('UI_LIKES')}</Typography>
                            <Slider
                                defaultValue={configuredAction.minCost.like}
                                onChangeCommitted={(e, v) => setActionResLike(v)}
                                getAriaValueText={(v) => v}
                                disabled={Math.min(configuredAction.maxCost.like, freeResources.like) < 1}
                                aria-labelledby="discrete-slider-small-steps"
                                step={10}
                                min={configuredAction.minCost.like}
                                max={Math.min(configuredAction.maxCost.like, freeResources.like)}
                                valueLabelDisplay="auto"
                            />
                            <Typography>{t('UI_PSX')}</Typography>
                            <Slider
                                defaultValue={0}
                                onChangeCommitted={(e, v) => setActionResPSX(v)}
                                disabled={Math.min(100, freeResources.psx) < 1}
                                getAriaValueText={(v) => v}
                                aria-labelledby="discrete-slider-small-steps"
                                step={1}
                                min={0}
                                max={Math.min(100, freeResources.psx)}
                                valueLabelDisplay="auto"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleClose} color="primary">
                                {t('UI_CANCEL')}
                            </Button>
                            <Button variant="contained" onClick={handleAddToList} color="primary">
                                {t('UI_ADD_LIST')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>

            </Grid>

            { (configuredActions.length > 0 && this.state.totalActionPointsConfigured <= this.props.currentUser.level) ? (
                <Slide direction="up" in={this.state.totalActionPointsConfigured > 0}>
                    <Badge badgeContent={this.state.totalActionPointsConfigured} color="primary" className={classes.fab}>
                        <Fab aria-label="Execute" color="secondary" onClick={handleDoActions}>
                            <PlayArrowIcon />
                        </Fab>
                    </Badge>
                </Slide>
            ) : null}
        </Collapse>);
    }
}

export default withStyles(classes)(withTranslation()(Actions));