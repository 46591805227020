import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { apolloClient } from '@apollo/client';

import { Grid, Chip, Typography, Divider, List, ListItem, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';

import User from '../../models/User'

import { withTranslation } from 'react-i18next'

import { getActions, saveActions, useCurrentUser } from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';

import SmallParty from '../Party/SmallParty'


// const [doActions, { data }] = useMutation(User.DOACTIONS);

let classes = {};

class PublicProfile extends React.Component {

    constructor(props) {

        super(props);

        // console.log("Props :", props);

        this.state = {
            user: (this.props.currentUser) ? this.props.currentUser : (this.props.user) ? this.props.user : null,
            isCurrent: (this.props.currentUser) ? true : false
        }

    }

    async componentDidMount() {

        // Get user from id :
        console.log("This state :", this.state, this.props);
        if (!this.state.user && this.props.match.params.id) {

            console.log("Did mount :", this.props.match.params.id);

            try {

                const { data, loading } = await this.props.apolloClient.query({ query: User.USER, fetchPolicy: "no-cache", variables: { id: this.props.match.params.id } });
                
                console.log("Data :", data);
                let obj = JSON.parse(JSON.stringify(data.user));
                this.setState({ user: obj });
            } catch (err) {

                this.setState({ user: {
                    username: "Non existing player, YOU ?",
                    level: 1,
                    resources: {
                        points: 0,
                        like: 0,
                        dislike: 0
                    }
                } });
            }
        }
    }

    render() {

        if (!this.state.user) return null;

        const { onUpdateUser } = this.props

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper style={{ padding: '15px' }}>
                        <h2>Identity</h2>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Username</TableCell>
                                        <TableCell align="right">{this.state.user.username}</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Level</TableCell>
                                        <TableCell align="right">{this.state.user.level}</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Country</TableCell>
                                        <TableCell align="right">{this.state.user.country}</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Party</TableCell>
                                        <TableCell align="right">{(this.state.user.party) ? <SmallParty currentUser={this.state.user} apolloClient={apolloClient} onUpdateUser={onUpdateUser} party={this.state.user.party} /> : 'Indep'}</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Points</TableCell>
                                        <TableCell align="right">{this.state.user.resources.points}</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Likes</TableCell>
                                        <TableCell align="right">{this.state.user.resources.like}</TableCell>
                                    </TableRow>
                                    <TableRow key="">
                                        <TableCell component="th" scope="row">Dislike</TableCell>
                                        <TableCell align="right">{this.state.user.resources.dislike}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

}

export default withTranslation()(PublicProfile);
