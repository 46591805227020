import { useState } from 'react';
import { validateOperation } from '@apollo/client/link/utils';

import { Storage } from '@capacitor/storage';

export default class Memory {

    constructor() {
        //
    }

    async getToken() {
        const userToken = await Storage.get({ key: 'token' });
        const v = JSON.parse(userToken.value);
        console.log("[getToken] Token :", v);
        return v?.token;
    }

    async saveToken(userToken) {
        await Storage.set({ key: 'token', value: JSON.stringify(userToken) });
    }

    // useToken() {
    //     const getToken = () => {
    //         const tokenString = Storage.get({ key: 'token' });
    //         const userToken = JSON.parse(tokenString);
    //         return userToken?.token
    //     }
    //     const [token, setToken] = useState(getToken());
    //     const saveToken = userToken => {
    //         Storage.set({ key: 'token', JSON.stringify(value: JSON.stringify(userToken)) });
    //         setToken(userToken.token);
    //     };
    //     return {
    //         setToken: saveToken,
    //         token
    //     }
    // }

    // useCurrentUser() {

    //     const getCurrentUser = () => {
    //         const currentUserString = Storage.get({ key: 'currentUser' });
    //         const currentUser = JSON.parse(currentUserString);
    //         return currentUser;
    //     };

    //     const [currentUser, setCurrentUser] = useState(getCurrentUser());

    //     const saveCurrentUser = currentUser => {
    //         Storage.set({ key: 'currentUser', JSON.stringify(value: JSON.stringify(currentUser)) });
    //         setCurrentUser(currentUser);
    //     };

    //     return {
    //         setCurrentUser: saveCurrentUser,
    //         currentUser
    //     }
    // }

    async disconnect() {
        await Storage.clear();
    }

    async saveCurrentUser(currentUser) {
        await Storage.set({ key: 'currentUser', value: JSON.stringify(currentUser) });
    };

    async getCurrentUser() {
        const currentUserString = await Storage.get({ key: 'currentUser' });
        const v = JSON.parse(currentUserString.value);
        return validateOperation;
    }


    async getAttacks() {
        const objString = await Storage.get({ key: 'attacks' });
        const v = JSON.parse(objString.value);
        return v;
    }

    async saveAttacks(obj) {
        await Storage.set({ key: 'attacks', value: JSON.stringify(obj) });
    }


    async getOptions() {
        const objString = await Storage.get({ key: 'options' });
        const v = JSON.parse(objString.value);
        return v;
    }

    async saveOptions(obj) {
        await Storage.set({ key: 'options', value: JSON.stringify(obj) });
    }




    async getActions() {
        const objString = await Storage.get({ key: 'actions' });
        const v = JSON.parse(objString.value);
        return v;
    }

    async saveActions(obj) {
        await Storage.set({ key: 'actions', value: JSON.stringify(obj) });
    }


}

