import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Chip, AppBar, Grid, Typography, Divider, List, ListItem, Slider, CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';


import User from '../../models/User'
import SmallParty from './SmallParty'
import SmallProfile from '../Profile/SmallProfile'
import PartyDialog from './Party'

import { withTranslation } from 'react-i18next'

import { getActions, saveActions, useCurrentUser } from '../../hooks/memory';
import { shortNumber } from '../../hooks/numberUtils'

import { DateTime, Duration, Interval } from 'luxon';
import numeral from 'numeral'

var Filter = require('bad-words'),
    badWordsCleaner = new Filter();


const classes = (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class PartyPage extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            creatingParty: false,
            searchPartyValues: [],
            parties: [],
            party: null,
            createPartyType: '',
            createPartyValues: [],
            stats: { users: [] }
        }

        this.getPartyStats = this.getPartyStats.bind(this);
        this.findParties = this.findParties.bind(this);
    }

    componentDidMount() {
        if (this.props.currentUser && this.props.currentUser.party) {
            this.getPartyStats();
        } else {
            this.findParties();
        }
    }


    async findParties(values) {
        const { currentUser } = this.props;
        if (!currentUser) return [];
        const { data, loading } = await this.props.apolloClient.query({ query: User.PARTYS, variables: { country: currentUser.country, nb: 20, values: values }, fetchPolicy: "no-cache" });
        let obj = JSON.parse(JSON.stringify(data.partys));
        // console.log("Obj:", obj);
        this.setState({ parties: obj });
    }

    async getPartyStats() {
        const { currentUser } = this.props;
        if (!currentUser.party) return [];
        const { data, loading } = await this.props.apolloClient.query({ query: User.PARTYSTATS, variables: { party: currentUser.party.id }, fetchPolicy: "no-cache" });
        let obj = JSON.parse(JSON.stringify(data.statsParty));
        console.log("Obj statsParty :", obj);
        this.setState({ stats: obj });
    }



    render() {

        const { t, classes, currentUser, onUpdateUser, apolloClient, memory } = this.props;

        if (!currentUser) return null

        console.log("Current user :", currentUser);

        const canCreateParty = () => {
            return (currentUser.resources.money > 1000000 && currentUser.resources.like > 10)
        }

        const handleLeaveParty = async () => {

            if (window.confirm('this will make you loose 10% points, loose 20% likes and earn them as dislikes')) {

                await apolloClient.mutate({ mutation: User.UPDATEUSER, variables: { party: null } });
                await onUpdateUser();
            }
        }

        const handleChangeSearchParty = async (event) => {

            // Search party :
            this.findParties(event.target.value);
            this.setState({ searchPartyValues: event.target.value });
        };


        const handleCreatePartyType = (event) => {
            this.setState({ createPartyType: event.target.value });
        }
        const handleCreatePartyValues = (event) => {
            if (event.target.value.length > 3) {

                // Alert ...

            } else {

                this.setState({ createPartyValues: event.target.value });
            }
        }

        let arrValues = [...Array(User.NB_NOTIONS).keys()].map(n => ({ key: 'NOTION' + n, label: t('NOTION' + n) }));
        arrValues.sort((n1, n2) => (n1.label < n2.label) ? -1 : 1);

        const handleCreateParty = async () => {

            if (this.state.creatingParty) return;

            const cleanedName = badWordsCleaner.clean(this.state.createPartyName)
            if (cleanedName !== this.state.createPartyName) {

                alert("Your party name contains forbidden words. Please change it.")
                return
            }

            if (canCreateParty()) {

                this.setState({
                    creatingParty: true
                }, async () => {

                    await this.props.apolloClient.mutate({ mutation: User.CREATEPARTY, variables: { name: this.state.createPartyName, country: currentUser.country, values: this.state.createPartyValues } });
                    onUpdateUser();
                    this.setState({ creatingParty: false });
                });
            }
        }

        return (
            <Grid container spacing={2}>

                { (currentUser.party) ?
                    (<Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={currentUser.party.name} subtitle="" />
                            <CardContent>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableBody>
                                            <TableRow key="party">
                                                <TableCell component="th" scope="row">Members</TableCell>
                                                <TableCell align="right">{currentUser.party.totalResources.nbMembers}</TableCell>
                                            </TableRow>
                                            <TableRow key="party">
                                                <TableCell component="th" scope="row">Values</TableCell>
                                                <TableCell align="right">{currentUser.party.values.map(v => t(v)).join(', ')}</TableCell>
                                            </TableRow>
                                            <TableRow key="party">
                                                <TableCell component="th" scope="row">Like</TableCell>
                                                <TableCell align="right">{currentUser.party.totalResources.like}</TableCell>
                                            </TableRow>
                                            <TableRow key="party">
                                                <TableCell component="th" scope="row">Dislike</TableCell>
                                                <TableCell align="right">{currentUser.party.totalResources.dislike}</TableCell>
                                            </TableRow>
                                            <TableRow key="party">
                                                <TableCell component="th" scope="row">Points</TableCell>
                                                <TableCell align="right">{currentUser.party.totalResources.points}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </CardContent>
                            <CardActions>
                                <Button variant="contained" color="primary" onClick={handleLeaveParty} >Leave this party</Button>
                            </CardActions>
                        </Card>
                    </Grid>) : null}

                { (currentUser.party) ?
                    (<Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title="Leaderboard" subtitle="Top 10 players of your party" />
                            <CardContent>

                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Position</TableCell>
                                                <TableCell align="left">Player</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.stats.users.map((user, idx) => (
                                                <TableRow key={user.idx}>
                                                    <TableCell component="th" scope="row">
                                                        #{idx + 1}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <SmallProfile user={user} memory={this.props.memory} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </CardContent>
                        </Card>
                    </Grid>) : null}

                { (!currentUser.party) ?
                    (<Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title="Find a party" subtitle="Find a party and become a member." />
                            <CardContent>
                                {/* Multi select chip avec les valeurs associées. */}
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="select-value-label">Choose ideologies</InputLabel>
                                    <Select
                                        labelId="select-value-label"
                                        id="demo-mutiple-chip"
                                        multiple
                                        value={this.state.searchPartyValues}
                                        onChange={handleChangeSearchParty}
                                        input={<Input id="select-multiple-chip" />}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={t(value)} className={classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {arrValues.map(n => (
                                            <MenuItem key={n.key} value={n.key} >
                                                {n.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <ul>
                                    {this.state.parties.map(p => (<li style={{ margin: 4 }}><SmallParty currentUser={currentUser} onUpdateUser={onUpdateUser} party={p} apolloClient={apolloClient} /></li>))}
                                </ul>

                                {(!this.state.parties || this.state.parties.length === 0) ? (<Typography>
                                    No party matches your values. Maybe create a new party ?
                                </Typography>) : null}
                            </CardContent>
                        </Card>
                    </Grid>) : null}

                { (!currentUser.party) ?
                    (<Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title="Create a party" subtitle="" />
                            <CardContent>
                                <Typography>
                                    This will cost 1m$ and 10 likes. The leader is the more powerful player of the party. But you will stay the "founder" forever.
                                    The name is auto generated, for legal reasons, it could be changed later under conditions.
                                </Typography>

                                <FormControl className={classes.formControl}>
                                    <InputLabel id="select-type-label">Choose type</InputLabel>
                                    <Select
                                        labelId="select-type-label"
                                        id="demo-mutiple-chip"
                                        value={this.state.createPartyType}
                                        onChange={handleCreatePartyType}
                                        input={<Input id="select-multiple-chip" />}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                <Chip key={selected} label={t(selected)} className={classes.chip} />
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="TYPE_FRONT" value="TYPE_FRONT" >
                                            {t('TYPE_FRONT')}
                                        </MenuItem>
                                        <MenuItem key="TYPE_PARTY" value="TYPE_PARTY" >
                                            {t('TYPE_PARTY')}
                                        </MenuItem>
                                        <MenuItem key="TYPE_LEAGUE" value="TYPE_LEAGUE" >
                                            {t('TYPE_LEAGUE')}
                                        </MenuItem>
                                        <MenuItem key="TYPE_MOVEMENT" value="TYPE_MOVEMENT" >
                                            {t('TYPE_MOVEMENT')}
                                        </MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                    <InputLabel id="select-value-label">Choose ideologies</InputLabel>
                                    <Select
                                        labelId="select-value-label"
                                        id="demo-mutiple-chip"
                                        multiple
                                        value={this.state.createPartyValues}
                                        onChange={handleCreatePartyValues}
                                        input={<Input id="select-multiple-chip" />}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={t(value)} className={classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {arrValues.map(n => (
                                            <MenuItem key={n.key} value={n.key} >
                                                {n.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="party_name"
                                        label="Party name"
                                        name="party_name"
                                        autoComplete="email"
                                        autoFocus
                                        onChange={e => { this.setState({ createPartyName: e.target.value }) }}
                                    />
                                </FormControl>
                                

                            </CardContent>
                            <CardActionArea>
                                <Button variant="contained" disabled={this.state.creatingParty || !canCreateParty()} color="primary" onClick={handleCreateParty}>
                                    Create
                                </Button>
                            </CardActionArea>
                        </Card>
                    </Grid>) : null}

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title="Next elections" subtitle="" />
                        <CardContent>
                            {/* List of candidates
                            Help a candidate. */}
                            <Chip
                                color="secondary"
                                label="Coming soon"
                            />
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        );
    }

}

export default withStyles(classes)(withTranslation()(PartyPage));